import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Event, Router} from '@angular/router';
import {FullscreenChartService} from '../../fullscreen-chart/fullscreen-chart.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {CustomChartData} from '../../ChartFactories/CustomChartData';
import {SamplingPoint} from '../../Models/SamplingPoint';
import {DashboardSamplingPointService} from '../dashboard-sampling-point.service';
import {MatTableDataSource} from '@angular/material/table';
import {Sort} from '@angular/material/sort';
import {ExportToCsv} from 'export-to-csv';
import {SPChartFactory} from '../../ChartFactories/SamplingPointFactory/SPChartFactory';
import {SPCalibratorsChartFactory} from '../../ChartFactories/SamplingPointFactory/ConcreteSamplingPointFactories/sp-calibrators-chart-factory';
import {SPTtmChartFactory} from '../../ChartFactories/SamplingPointFactory/ConcreteSamplingPointFactories/sp-ttm-chart-factory';
import {SPCalibSuccessChartFactory} from '../../ChartFactories/SamplingPointFactory/ConcreteSamplingPointFactories/sp-calib-success-chart-factory';
import {SPUgChartFactory} from '../../ChartFactories/SamplingPointFactory/ConcreteSamplingPointFactories/sp-ug-factory';
import {SPCalibSuccessHistoryChartFactory} from '../../ChartFactories/SamplingPointFactory/ConcreteSamplingPointFactories/sp-calib-success-history-chart-factory';
import {MdLegendDialogComponent} from '../../dashboard-machine/md-legend-dialog/md-legend-dialog.component';
import {NavbarService} from '../../header/navbar.service';
import {SPC1minC2ChartFactory} from '../../ChartFactories/SamplingPointFactory/ConcreteSamplingPointFactories/sp-c1-min-c2-chart-factory';
import {SPTTmVariationChartFactory} from '../../ChartFactories/SamplingPointFactory/ConcreteSamplingPointFactories/sp-ttm-variation-chart-factory';
import {tap} from 'rxjs/operators';
import {MatPaginator} from '@angular/material/paginator';

import { Moment } from 'moment';
import _moment from 'moment';
const moment = _moment;

@Component({
  selector: 'app-sampling-point-detail',
  templateUrl: './sampling-point-detail.component.html',
  styleUrls: ['./sampling-point-detail.component.css']
})
export class SamplingPointDetailComponent implements OnInit {

	maxDate: Date;
	sampling_points: any;

	showSamplingPointInfo = false;
	showSamplingPointChart: boolean[] = [true, true, true, true, true, true, true];
	showAnalysisHistory = true;

	samplingPointSettingsForm: FormGroup;
	ttmForm: FormGroup;
	calibrationSuccessForm: FormGroup;
	ttmVariationForm: FormGroup;
	c1minC2Form: FormGroup;
	sampling_pointCalSuccessHistoryForm: FormGroup;
	calibratorsForm: FormGroup;
	ugForm: FormGroup;

	spIdForm: FormGroup;
	CurrentSpId: number;
	CurrentSPNum = 0;
	CurrentNumberofSampleSamplingPoint = 30;

	numberOfSample: any[] = [0, 3, 5, 10, 20, 30, 50, 100, 200, 300];
	displayedColumns: string[];
	sampling_pointChartSettings: boolean[] = [false];

	sp_c1minc2_chart: CustomChartData;
	sp_calibSuccessHistory_chart: CustomChartData;
	sp_ttm_chart: CustomChartData;
	sp_calibSuccess_chart: CustomChartData;
	sp_calibrators_chart: CustomChartData;
	sp_ug_chart: CustomChartData;
	sp_ttm_variation_chart: CustomChartData;

	sampling_point_nb_analysis: any;
	sampling_pointCalibrationSuccessMoy: any;
	latestSampling_pointCalibrationSuccess_5: any;
	averageQualityScore_5: any;
	numberInvalidUgLast_5: any;
	numberInvalidLoaded = false;

	detailedAnalysisHistory: any[] = [];

	selectedMachines: any[] = [];

	isLoading = false;
	dataSource;

	@ViewChild(MatPaginator) paginator: MatPaginator;

	missing_diag_num: Map<number, number> = new Map<number, number>();
	missing_test_num: Map<number, number> = new Map<number, number>();

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private service: DashboardSamplingPointService,
		private chartService: FullscreenChartService,
		private formBuilder: FormBuilder,
		private snackbar: MatSnackBar,
		public dialog: MatDialog,
		public nav: NavbarService) {
	}

	ngOnInit(): void {

		if (this.nav._role === 'admin' || this.nav._role === 'super-admin') {
			this.displayedColumns = ['machine_tag', 'analysis_id', 'sampling_time', 'inlet_num', 'cartouchesdisponibles', 'lotc01', 'ug', 'ug*', 'ttm', 'vmr', 'c1', 'v1', 'v2', 'c2', 'c1MinusC2', 'v1MinusV2', 'calibration_success', 'quality_score', 'error_code'];
		} else {
			this.displayedColumns = ['machine_tag', 'analysis_id', 'sampling_time', 'inlet_num', 'cartouchesdisponibles', 'lotc01', 'ug'];
		}

		const currentYear = new Date().getFullYear();
		const currentMonth = new Date().getMonth();
		const currentDay = new Date().getDate();
		this.maxDate = new Date(currentYear, currentMonth, currentDay);

		this.samplingPointSettingsForm = this.formBuilder.group({
			dateFormat: [this.maxDate, [Validators.required]],
			numberOfSample: [this.CurrentNumberofSampleSamplingPoint, [Validators.required]]
		});
		this.ttmForm = this.formBuilder.group({
			dateFormat: [this.maxDate, [Validators.required]],
			numberOfSample: ['', [Validators.required]]
		});

		this.calibrationSuccessForm = this.formBuilder.group({
			dateFormat: [this.maxDate, [Validators.required]],
			numberOfSample: ['', [Validators.required]]
		});

		this.ttmVariationForm = this.formBuilder.group({
			dateFormat: [this.maxDate, [Validators.required]],
			numberOfSample: ['', [Validators.required]]
		});

		this.c1minC2Form = this.formBuilder.group({
			dateFormat: [this.maxDate, [Validators.required]],
			numberOfSample: ['', [Validators.required]]
		});

		this.sampling_pointCalSuccessHistoryForm = this.formBuilder.group({
			dateFormat: [this.maxDate, [Validators.required]],
			numberOfSample: ['', [Validators.required]]
		});

		this.calibratorsForm = this.formBuilder.group({
			dateFormat: [this.maxDate, [Validators.required]],
			numberOfSample: ['', [Validators.required]]
		});

		this.ugForm = this.formBuilder.group({
			dateFormat: [this.maxDate, [Validators.required]],
			numberOfSample: ['', [Validators.required]]
		});

		this.spIdForm = new FormGroup({
			samplingPoint: new FormControl()
		});

		this.isLoading = true;

		this.service.getSamplingPointById(this.route.snapshot.paramMap.get('samplingPointId')).subscribe((sampling: SamplingPoint) => {
			this.sampling_points = sampling;
			this.initializeAnalysisHistory(this.sampling_points, 0, 10);

			this.initializeSamplingPointInfo(this.sampling_points);

		});
	}

	async ngAfterViewInit() {
		this.paginator.page
			.pipe(
				tap(() => {
					this.missing_diag_num = new Map<number, number>();
					this.missing_test_num = new Map<number, number>();
					this.initializeAnalysisHistory(this.sampling_points, this.paginator.pageIndex, this.paginator.pageSize);
				}))
			.subscribe();
	}

	checkForPermission(): boolean{
		if (this.nav._role === 'admin' || this.nav._role === 'super-admin'){
			return true;
		}else {
			return false;
		}
	}

	gotoSamplingPoint() {
		this.router.navigate(['/dashboards/samplingPoint']);
	}

	spIdChanged($event: any) {
		this.CurrentSPNum = this.sampling_points.find(sp => sp.sp_id === $event.value);
		this.CurrentSpId = $event.value;
	}

	numberOfSampleSamplingPointChanged($event: any) {
		this.CurrentNumberofSampleSamplingPoint = $event.value;
	}


	async initializeSamplingPointInfo(sampling: any) {
		await this.getLatestSamplingPointInfo(sampling);
		await this.setAllSamplingPointCharts();
	}

	async getLatestSamplingPointInfo(sampling: any) {
		this.sampling_point_nb_analysis = await this.service.getSamplingPointLatestVariable(sampling.sp_id, 'nb_analysis');
		this.sampling_pointCalibrationSuccessMoy = await this.service.getSamplingPointLatestVariableWithClLevel(sampling.sp_id, 'calib_success_samplingpoint_all');
		this.latestSampling_pointCalibrationSuccess_5 = await this.service.getSamplingPointLatestVariableWithClLevel(sampling.sp_id, 'calib_success_samplingpoint_5');
		this.averageQualityScore_5 = await this.service.getAverageVariableForNumberOfAnalysis(sampling.sp_id, 1260, 5)
	}

	async refreshSamplingPointInfo() {
		await this.getLatestSamplingPointInfo(this.sampling_points);
		await this.setAllSamplingPointCharts();
	}


	async initializeAnalysisHistory(sampling: any, pageIndex: number, pageSize: number) {
		this.isLoading = true;
		this.detailedAnalysisHistory = [];
		const range = pageIndex * pageSize;

		const analysisIds = await this.service.getSamplingPointAnalysisIdHistory(sampling.sp_id, pageSize, range);
		for (const analysisId of analysisIds) {
			const detailedAnalysis = await this.service.getSamplingPointAnalysisFullDetail(analysisId.analysis_id);
			if (detailedAnalysis.result_vmr == null) {
				detailedAnalysis.result_vmr = {"value": 5 / detailedAnalysis.result_volume.value}
			}
			if (detailedAnalysis.result_error_code && detailedAnalysis.result_error_code.value !== 0) {
				detailedAnalysis.error_desc = (await this.service.getErrorCodeDefinition(detailedAnalysis.result_error_code.value)).message;
			}
			detailedAnalysis.missing_variable = await this.service.getAnalysisMissingVariables(detailedAnalysis.analysis_id);
			this.detailedAnalysisHistory.push(detailedAnalysis);
			this.dataSource = new MatTableDataSource(this.detailedAnalysisHistory);
		}
		this.isLoading = false;
		if(!this.numberInvalidLoaded) {
			this.numberInvalidUg()
		}
	}

		numberInvalidUg(){
		let number_of_invalid = 0
		for(let i = 0; i < 5; i++) {
			if(this.detailedAnalysisHistory[i].result_ug_optimized_rounded && this.detailedAnalysisHistory[i].result_ug_optimized_rounded.value == null){
				number_of_invalid+=1;
			}
		}
		this.numberInvalidUgLast_5 = number_of_invalid;
		this.numberInvalidLoaded = true;
	}

	openSnackBar(message, action) {
		this.snackbar.open(message, action, {duration: 5000});
	}

	openLegend(): void {
		this.dialog.open(MdLegendDialogComponent, {
			width: '80%',
			height: '80%'
		});
	}


	sortData(sort: Sort) {
		const data = this.detailedAnalysisHistory.slice();
		if (!sort.active || sort.direction === '') {
			this.detailedAnalysisHistory = data;
			return;
		}
		this.dataSource = new MatTableDataSource(data.sort((a, b) => {
			const isAsc = sort.direction === 'asc';
			switch (sort.active) {
				case 'ttm':
					return compare(a.ttm, b.ttm, isAsc);
				case 'lotc01':
					return compare(a.lotc01, b.lotc01, isAsc);
				case 'c1MinusC2':
					return compare(a.c1MinusC2, b.c1MinusC2, isAsc);
				case 'calibration_success':
					return compare(a.calibration_success, b.calibration_success, isAsc);
				case 'sampling_time':
					return compare(a.sampling_time, b.sampling_time, isAsc);
				default:
					return 0;
			}
		}));
	}

	applyFilter(filterValue: string) {
		this.dataSource.filterPredicate = (data: any, filter: string) => {
			return data.machine_tag.toLocaleLowerCase().includes(filter) || data.cart_available.value.toLocaleString().toLocaleLowerCase().includes(filter)
				|| data.result_value_ug_rounded.value.toLocaleString().toLocaleLowerCase().includes(filter) || data.analysis_id.toLocaleString().toLocaleLowerCase().includes(filter)
				|| data.sampling_time.toLocaleLowerCase().includes(filter);
		};
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	setAllSamplingPointCharts() {
		if (this.samplingPointSettingsForm.valid) {
			const selectedDate: Moment = this.samplingPointSettingsForm.get('dateFormat').value;
			const time = moment(selectedDate).add(1, 'day').format('YYYY-MM-DD HH:mm:ss');

			this.createSamplingPointChart(new SPCalibratorsChartFactory(this.chartService), this.sampling_points.sp_id,
				this.CurrentNumberofSampleSamplingPoint, time, 'Sampling point Calibrators Chart');
			this.createSamplingPointChart(new SPTtmChartFactory(this.chartService), this.sampling_points.sp_id,
				this.CurrentNumberofSampleSamplingPoint, time, 'Sampling point TTm Chart');
			this.createSamplingPointChart(new SPCalibSuccessChartFactory(this.chartService), this.sampling_points.sp_id,
				this.CurrentNumberofSampleSamplingPoint, time, 'Sampling point Calibration Success Chart');
			this.createSamplingPointChart(new SPUgChartFactory(this.chartService), this.sampling_points.sp_id,
				this.CurrentNumberofSampleSamplingPoint, time, 'Sampling point Ug Chart');
			this.createSamplingPointChart(new SPCalibSuccessHistoryChartFactory(this.chartService), this.sampling_points.sp_id,
				this.CurrentNumberofSampleSamplingPoint, time, 'Sampling point Calibration Success History Chart');
			this.createSamplingPointChart(new SPC1minC2ChartFactory(this.chartService), this.sampling_points.sp_id,
				this.CurrentNumberofSampleSamplingPoint, time, 'Sampling point C1-C2 Chart');
			this.createSamplingPointChart(new SPTTmVariationChartFactory(this.chartService), this.sampling_points.sp_id,
				this.CurrentNumberofSampleSamplingPoint, time, 'Sampling point TTm Variation Chart');
		} else {
			this.openSnackBar('Please fill the field correctly', 'Close');
		}

	}

	/* Factory Pattern
* Chart creation from a concrete factory through its base interface */
	async createSamplingPointChart(facto: SPChartFactory, sp_id: number, limit: number, time: string, chartName: string) {
		if (chartName === 'Sampling point Calibration Success History Chart') {
			this.sp_calibSuccessHistory_chart = await facto.createChart(sp_id, limit, time);
		} else if (chartName === 'Sampling point C1-C2 Chart') {
			this.sp_c1minc2_chart = await facto.createChart(sp_id, limit, time);
		} else if (chartName === 'Sampling point TTm Chart') {
			this.sp_ttm_chart = await facto.createChart(sp_id, limit, time);
		} else if (chartName === 'Sampling point Calibrators Chart') {
			this.sp_calibrators_chart = await facto.createChart(sp_id, limit, time);
		} else if (chartName === 'Sampling point Calibration Success Chart') {
			this.sp_calibSuccess_chart = await facto.createChart(sp_id, limit, time);
		} else if (chartName === 'Sampling point Ug Chart') {
			this.sp_ug_chart = await facto.createChart(sp_id, limit, time);
		}else if (chartName === 'Sampling point TTm Variation Chart') {
			this.sp_ttm_variation_chart = await facto.createChart(sp_id, limit, time);
		}
	}

	exportToCsv(chartName: string) {
		let temp = [];
		if (chartName === 'Sampling point C1-C2 Chart') {
			temp = this.sp_c1minc2_chart.chartData;
		} else if (chartName === 'Sampling point TTm Chart') {
			temp = this.sp_ttm_chart.chartData;
		} else if (chartName === 'Sampling point Calibrators Chart') {
			temp = this.sp_calibrators_chart.chartData;
		} else if (chartName === 'Sampling point Calibration Success Chart') {
			temp = this.sp_calibSuccess_chart.chartData;
		} else if (chartName === 'Sampling point Ug Chart') {
			temp = this.sp_ug_chart.chartData;
		} else if (chartName === 'Sampling point Calibration Success History Chart'){
			temp = this.sp_calibSuccessHistory_chart.chartData;
		}else if (chartName === 'Sampling point TTm Variation Chart'){
			temp = this.sp_ttm_variation_chart.chartData;
		}

		const file = [];
		let i = 0;
		for (const t of temp) {
			const header = t.name;
			const data = t.y;
			const xaxis = t.x;
			data.forEach(element => {
				file.push([header, element, xaxis[i]]);
				i++;
			});
			i = 0;
		}

		const options = {
			filename: chartName,
			fieldSeparator: ',',
			quoteStrings: '"',
			decimalSeparator: '.',
			showLabels: true,
			showTitle: true,
			title: chartName,
			useTextFile: false,
			useBom: true,
			// useKeysA
			sHeaders: true,
		};
		const csvExporter = new ExportToCsv(options);

		csvExporter.generateCsv(file);
	}

	openChartFullscreen(chartName: string) {

		const selectedDate: Moment = this.samplingPointSettingsForm.get('dateFormat').value;
		const time = moment(selectedDate).add(1, 'day').format('YYYY-MM-DD HH:mm:ss');

		if (chartName === 'Sampling point TTm Chart') {
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'Sampling point TTm Chart', this.sampling_points.sp_id, this.sampling_points.sampling_name, null, null, time,
					this.CurrentNumberofSampleSamplingPoint])
			);
			window.open(url, '_blank');
		} else if (chartName === 'Sampling point Calibration Success Chart') {
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'Sampling point Calibration Success Chart', this.sampling_points.sp_id, this.sampling_points.sampling_name, null, null, time,
					this.CurrentNumberofSampleSamplingPoint])
			);
			window.open(url, '_blank');
		} else if (chartName === 'Sampling point TTm Variation Chart') {
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'Sampling point TTm Variation Chart', this.sampling_points.sp_id, this.sampling_points.sampling_name, null, null, time,
					this.CurrentNumberofSampleSamplingPoint])
			);
			window.open(url, '_blank');
		} else if (chartName === 'Sampling point C1-C2 Chart') {
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'Sampling point C1-C2 Chart', this.sampling_points.sp_id, this.sampling_points.sampling_name, null, null, time,
					this.CurrentNumberofSampleSamplingPoint])
			);
			window.open(url, '_blank');
		} else if (chartName === 'Sampling point Calibration Success History Chart') {
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'Sampling point Calibration Success History Chart', this.sampling_points.sp_id, this.sampling_points.sampling_name, null, null,
					time, this.CurrentNumberofSampleSamplingPoint])
			);
			window.open(url, '_blank');

		} else if (chartName === 'Sampling point Calibrators Chart') {
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'Sampling point Calibrators Chart', this.sampling_points.sp_id, this.sampling_points.sampling_name, null, null, time,
					this.CurrentNumberofSampleSamplingPoint])
			);
			window.open(url, '_blank');
		} else if (chartName === 'Sampling point Ug Chart') {
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'Sampling point Ug Chart', this.sampling_points.sp_id, this.sampling_points.sampling_name, null, null, time,
					this.CurrentNumberofSampleSamplingPoint])
			);
			window.open(url, '_blank');
		}
	}

	convertASCIItoChar(code: number) {
		if (code) {
			return String.fromCharCode(code);
		} else {
			return '';
		}
	}

	modifyChart(chart: CustomChartData, newX: [string, string], newY: [string, string]) {
		chart.chartLayout = {
			// items with a `name` attribute in template.images will be added to any
			// plot using this template
			images: [{
				source: '../../../../favicon.ico',
				xref: 'paper',
				yref: 'paper',
				x: 0.5,
				y: 0.5,
				xanchor: 'center',
				yanchor: 'middle',
				sizex: 0.7,
				sizey: 0.7,
				opacity: 0.1,
				layer: 'below'
			}],
			legend: {
				x: 0,
				y: 1.15,
				orientation: 'h',
				itemsizing: 'constant',
				itemwidth: 25
			},
			margin: {
				l: 35,
				r: 5,
				b: 50,
				t: 10
			},
			xaxis: {
				tickformat: '%d %b',
				tickangle: -45,
				showgrid: true,
				nticks: 36,
				showline: true,
				zeroline: false,
				mirror: true,
				hoverformat: '%Y-%m-%d %H:%M:%S',
				autorange: false,
				range: [new Date(newX[0]), new Date(newX[1])]
			},
			yaxis: {
				range: newY,
				showgrid: true,
				showline: true,
				zeroline: false,
				mirror: true
			},
			modebar: {
				color: '#000000',
				bgcolor: '#FFFFFF'
			}
		};
	}
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
	return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
