import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Machine} from 'src/app/Models/Machine';
import {DashboardMachineService} from '../dashboard-machine.service';
import {MatTableDataSource} from '@angular/material/table';

import {MatSnackBar} from '@angular/material/snack-bar';
import {Sort} from '@angular/material/sort';
import {ExportToCsv} from 'export-to-csv';
import {MdLegendDialogComponent} from '../md-legend-dialog/md-legend-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {CustomChartData} from 'src/app/ChartFactories/CustomChartData';
import {FullscreenChartService} from 'src/app/fullscreen-chart/fullscreen-chart.service';
import {MChartFactory} from 'src/app/ChartFactories/MachineFactory/MChartFactory';
import {MCalibSuccessHistoryChartFactory} from 'src/app/ChartFactories/MachineFactory/ConcreteMachineFactories/m-calib-success-history-chart-factory';
import {MAvgDeltaPe01Pe03ChartFactory} from 'src/app/ChartFactories/MachineFactory/ConcreteMachineFactories/m-avg-delta-pe01-pe03-chart-factory';
import {MTotalFiltrationTimeS0S1ChartFactory} from 'src/app/ChartFactories/MachineFactory/ConcreteMachineFactories/m-total-filtration-time-s0-s1-chart-factory';
import {MatPaginator} from '@angular/material/paginator';
import {tap} from 'rxjs/operators';
import {MTtmChartFactory} from '../../ChartFactories/MachineFactory/ConcreteMachineFactories/m-ttm-chart-factory';
import {MCalibSuccessChartFactory} from '../../ChartFactories/MachineFactory/ConcreteMachineFactories/m-calib-success-chart-factory';
import {MCalibratorsChartFactory} from '../../ChartFactories/MachineFactory/ConcreteMachineFactories/m-calibrators-chart-factory';
import {MTemperatureMinMaxChartFactory} from '../../ChartFactories/MachineFactory/ConcreteMachineFactories/m-temperature-min-max-chart-factory';
import {MTresholdFluorescenceChartFactory} from '../../ChartFactories/MachineFactory/ConcreteMachineFactories/m-treshold-fluorescence-chart-factory'
import {MUgChartFactory} from '../../ChartFactories/MachineFactory/ConcreteMachineFactories/m-ug-chart-factory';
import {MachineLimitsComponent} from '../../admin-panel/machine-settings/machine-limits/machine-limits.component';
import {NavigationService} from '../../navigation-service.service';
import {NavbarService} from '../../header/navbar.service';
import {MFillTimeS0ChartFactory} from '../../ChartFactories/MachineFactory/ConcreteMachineFactories/m-fill-time-s0-chart-factory';

import { Moment } from 'moment';
import _moment from 'moment';
const moment = _moment;

@Component({
	selector: 'app-machine-detail',
	templateUrl: './machine-detail.component.html',
	styleUrls: ['./machine-detail.component.css']
})
export class MachineDetailComponent implements OnInit {

	maxDate: Date;
	machineSettingsForm: FormGroup;
	machineMaintenanceForm: FormGroup;

	showMaintenanceData = true;
	lastMaintenace: string;
	nextMaintenance: string;
	nbAnalysisBeforeMaintenance: number;
	nbAnalysisSinceMaintenace: number;
	nbDaysSinceMaintenance: number;

	m_calibSuccessHistory_chart: CustomChartData;
	m_avg_delta_pe01_pe03: CustomChartData;
	m_total_filtration_time_s0_s1: CustomChartData;
	m_ttm_chart: CustomChartData;
	m_calibSuccess_chart: CustomChartData;
	m_calibrators_chart: CustomChartData;
	m_ug_chart: CustomChartData;
	m_fillTime_S0: CustomChartData;
	m_temperature_min_max_chart: CustomChartData;
	m_treshold_fluorescence_chart: CustomChartData;

	numberOfSample: any[] = [0, 3, 5, 10, 20, 30, 50, 100, 200, 300];
	displayedColumns: string[];

	detailedAnalysisHistory: any[] = [];

	sampling_points: any[] = [];

	showEventHistory = false;
	showAnalysisHistory = true;
	showMachineChart: boolean[] = [true, true, true, true, true, true, true, true];
	showMachineInfo = true;
	machineChartSettings: boolean[] = [false];

	machine: any;
	machine_tag: string;
	machineStatus:string;
	machineCalibrationSuccessMoy: any;
	latestMachineCalibrationSuccess_5: any;
	averageQualityScore_5: any;
	numberInvalidUgLast_5: any;
	numberInvalidLoaded = false;
	machine_nb_analysis: any;

	spIdForm: FormGroup;
	CurrentSpId: number;
	CurrentInletNum: number = 0;
	CurrentNumberOfSampleMachine = 30;

	isLoading = false;
	dataSource;

	isAllowed = false;
	@ViewChild(MatPaginator) paginator: MatPaginator;

	missing_diag_num: Map<number, number> = new Map<number, number>();
	missing_test_num: Map<number, number> = new Map<number, number>();

	displayDismissedNotifications: boolean;
	fetchingNotifications: boolean;
	emptyList: boolean = true;
	notifications: any;
	timeFrame: Date;
	displayedNotifications: any[] = [];
	appliedNotifTypeFilter: any[] = [];
	appliedNotifTimeFrameFilter: String = 'Last 7 days';
	notifTimeFrameFilters: String[] = ['All', 'Last 30 days', 'Last 14 days', 'Last 7 days', 'Today'];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private service: DashboardMachineService,
		private chartService: FullscreenChartService,
		private formBuilder: FormBuilder,
		private snackbar: MatSnackBar,
		public dialog: MatDialog,
		private navigation: NavigationService,
		public nav: NavbarService,
	) {
	}

	async ngOnInit() {

		if (this.nav._role === 'admin' || this.nav._role === 'super-admin') {
			this.displayedColumns = ['samplingpoint_name', 'analysis_id', 'sampling_time', 'analysis_time', 'inlet_num', 'cartouchesdisponibles', 'lotc01', 'ug', 'ug*', 'ttm', 'vmr', 'c1', 'v1', 'v2', 'c2', 'c1MinusC2', 'v1MinusV2', 'calibration_success', 'quality_score', 'error_code'];
		} else {
			this.displayedColumns = ['samplingpoint_name', 'analysis_id', 'sampling_time', 'analysis_time', 'inlet_num', 'cartouchesdisponibles', 'lotc01', 'ug'];
		}

		const currentYear = new Date().getFullYear();
		const currentMonth = new Date().getMonth();
		const currentDay = new Date().getDate();
		this.maxDate = new Date(currentYear, currentMonth, currentDay);

		this.machineSettingsForm = this.formBuilder.group({
			dateFormat: [this.maxDate, [Validators.required]],
			numberOfSample: [this.CurrentNumberOfSampleMachine, [Validators.required]]
		});

		this.machineMaintenanceForm = this.formBuilder.group({
			nextMaintenance: {'value': this.nextMaintenance, disabled: true},
			lastMaintenance: {'value': this.lastMaintenace, disabled: true},
			nbAnalysisBeforeMaintenance: {'value': this.nbAnalysisBeforeMaintenance, disabled: true},
			nbAnalysisSinceMaintenace: {'value': this.nbAnalysisSinceMaintenace, disabled: true},
			nbDaysSinceMaintenance: {'value': this.nbDaysSinceMaintenance, disabled: true}
		});

		this.isLoading = true;
		this.service.getMachine(this.route.snapshot.paramMap.get('id')).subscribe(async (data: Machine) => {
			this.machine = data;
			this.machine_tag = this.machine.machine_tag;
			this.initializeAnalysisHistory(this.machine.machine_id, 0, 10);
			this.machineStatus = await this.service.getMachinesStatus(this.machine.machine_id)
			this.initializeMachineInfo(this.machine.machine_id);
			this.isLoading = true;
			this.updateTimeFrameAndNotif();
			const nextMaintenanceData = await this.service.getNextMaintenanceDate(this.machine.machine_id, this.machine.last_maintenance, this.machine
				.nb_analysis_before_maintenance);
			this.lastMaintenace = moment(this.machine.last_maintenance).format('YYYY-MM-DD');
			this.nbAnalysisBeforeMaintenance = this.machine.nb_analysis_before_maintenance;
			this.nextMaintenance = nextMaintenanceData.nextMaintenanceDate;
			this.nbAnalysisSinceMaintenace = nextMaintenanceData.nbAnalysisSinceMaintenance;
			this.nbDaysSinceMaintenance = nextMaintenanceData.nbDaysSinceMaintenance;
		});
	}

	async ngAfterViewInit() {
		this.paginator.page
			.pipe(
				tap(() => {
					this.missing_diag_num = new Map<number, number>();
					this.missing_test_num = new Map<number, number>();
					this.initializeAnalysisHistory(this.machine.machine_id, this.paginator.pageIndex, this.paginator.pageSize);
				}))
			.subscribe();
	}

	updateTimeFrameAndNotif() {
		this.timeFrame = new Date();


		if (this.appliedNotifTimeFrameFilter === 'Last 30 days') {
			this.timeFrame.setDate(this.timeFrame.getDate() - 30);
		} else if (this.appliedNotifTimeFrameFilter === 'Last 14 days') {
			this.timeFrame.setDate(this.timeFrame.getDate() - 14);
		} else if (this.appliedNotifTimeFrameFilter === 'Last 7 days') {
			this.timeFrame.setDate(this.timeFrame.getDate() - 7);
		} else if (this.appliedNotifTimeFrameFilter === 'All') {
			this.timeFrame.setFullYear(this.timeFrame.getFullYear() - 100);
		}
		// Else: today. Only change the time like the others.
		this.timeFrame.setHours(0, 0, 0, 0);

		this.getNotifications();
		this.isLoading = false;
	}

	getNotifications(): void {
		this.fetchingNotifications = true;
		this.service.getNotificationByMachineTag(this.machine_tag, this.timeFrame.toISOString()).subscribe(value => {
			this.notifications = value;
			this.notifications.forEach(x => {
				if (x.exceeded_cl != null) {
					x.exceeded_cl = x.exceeded_cl.replace('(', '').replace(')', '').split(',');

					if (x.exceeded_cl[0]) {
						x.lcl_a = +x.exceeded_cl[0];
					}
					if (x.exceeded_cl[1]) {
						x.lcl_w = +x.exceeded_cl[1];
					}
					if (x.exceeded_cl[2]) {
						x.ucl_w = +x.exceeded_cl[2];
					}
					if (x.exceeded_cl[3]) {
						x.ucl_a = +x.exceeded_cl[3];
					}
				}
			});
			this.fetchingNotifications = false;
		}, err => {
			this.fetchingNotifications = false;
		});
	}
	async initializeMachineInfo(machine_id: number) {
		await this.getLatestMachineInfo(machine_id);
		await this.setAllMachineCharts();
	}

	async initializeAnalysisHistory(machine_id: number, pageIndex: number, pageSize: number) {
		this.isLoading = true;
		this.detailedAnalysisHistory = [];
		const range = pageIndex * pageSize;

		const analysisIds = await this.service.getMachineAnalysisIdHistory(machine_id, pageSize, range);
		for (const analysisId of analysisIds) {
			const detailedAnalysis = await this.service.getMachineAnalysisFullDetail(analysisId.analysis_id);
			if (detailedAnalysis.result_vmr==null) {
				detailedAnalysis.result_vmr = {"value": 5 / detailedAnalysis.result_volume.value}
			}
			if (detailedAnalysis.result_error_code && detailedAnalysis.result_error_code.value !== 0) {
				detailedAnalysis.error_desc = (await this.service.getErrorCodeDefinition(detailedAnalysis.result_error_code.value)).message;
			}
			detailedAnalysis.missing_variable = await this.service.getAnalysisMissingVariables(detailedAnalysis.analysis_id);
			this.detailedAnalysisHistory.push(detailedAnalysis);
			this.dataSource = new MatTableDataSource(this.detailedAnalysisHistory);
		}
		this.isLoading = false;
		if(!this.numberInvalidLoaded) {
			this.numberInvalidUg()
		}
	}

	async refreshMachineInfo() {
		await this.getLatestMachineInfo(this.machine.machine_id);
		await this.setAllMachineCharts();
	}

	async getLatestMachineInfo(machine_id: number) {
		this.machine_nb_analysis = await this.service.getLatestVariable(machine_id, 'nb_analysis');
		this.machineCalibrationSuccessMoy = await this.service.getLatestVariableWithClLevel(machine_id, 'calib_success_machine_all');
		this.latestMachineCalibrationSuccess_5 = await this.service.getLatestVariableWithClLevel(machine_id, 'calib_success_machine_5');
		this.averageQualityScore_5 = await this.service.getAverageVariableForNumberOfAnalysis(machine_id, 1260, 5)
	}

	numberInvalidUg(){
		let number_of_invalid = 0
		for(let i = 0; i < 5; i++) {
			if(this.detailedAnalysisHistory[i].result_ug_optimized_rounded && this.detailedAnalysisHistory[i].result_ug_optimized_rounded.value == null){
				number_of_invalid+=1
			}
		}
		this.numberInvalidUgLast_5 = number_of_invalid
		this.numberInvalidLoaded = true;
	}

	checkForPermission(): boolean{
		if (this.nav._role === 'admin' || this.nav._role === 'super-admin'){
			return true;
		}else {
			return false;
		}
	}

	back(): void {
		this.navigation.back();
	}

	spIdChanged($event: any) {
		this.CurrentInletNum = this.sampling_points.find(sp => sp.sp_id === $event.value).inlet_num;
		this.CurrentSpId = $event.value;
	}


	numberOfSampleMachineChanged($event: any) {
		this.CurrentNumberOfSampleMachine = $event.value;
	}

	setAllMachineCharts() {

		if (this.machineSettingsForm.valid) {
			const selectedDate: Moment = this.machineSettingsForm.get('dateFormat').value;
			const time = moment(selectedDate).add(1, 'day').format('YYYY-MM-DD HH:mm:ss');

			this.createMachineChart(new MCalibratorsChartFactory(this.chartService), this.machine.machine_id,
				this.CurrentNumberOfSampleMachine, time, 'Calibrators Chart');
			this.createMachineChart(new MTtmChartFactory(this.chartService), this.machine.machine_id,
				this.CurrentNumberOfSampleMachine, time, 'TTm Chart');
			this.createMachineChart(new MCalibSuccessChartFactory(this.chartService), this.machine.machine_id,
				this.CurrentNumberOfSampleMachine, time, 'Success Calibration Chart');
			this.createMachineChart(new MUgChartFactory(this.chartService), this.machine.machine_id,
				this.CurrentNumberOfSampleMachine, time, 'UG Chart');
			this.createMachineChart(new MCalibSuccessHistoryChartFactory(this.chartService), this.machine.machine_id,
				this.CurrentNumberOfSampleMachine, time, 'Machine Calibration Success History Chart');
			this.createMachineChart(new MAvgDeltaPe01Pe03ChartFactory(this.chartService), this.machine.machine_id,
				this.CurrentNumberOfSampleMachine, time, 'Avg Delta PE01 PE03');
			this.createMachineChart(new MTotalFiltrationTimeS0S1ChartFactory(this.chartService), this.machine.machine_id,
				this.CurrentNumberOfSampleMachine, time, 'Total Filtration Time S0 S1');
			this.createMachineChart(new MFillTimeS0ChartFactory(this.chartService), this.machine.machine_id,
				this.CurrentNumberOfSampleMachine, time, 'Filling Time S0');
			this.createMachineChart(new MTemperatureMinMaxChartFactory(this.chartService), this.machine.machine_id,
				this.CurrentNumberOfSampleMachine, time, 'Temperature Chart');
			this.createMachineChart(new MTresholdFluorescenceChartFactory(this.chartService), this.machine.machine_id,
				this.CurrentNumberOfSampleMachine, time, 'Treshold Fluorescence Chart');
		} else {
			this.openSnackBar('Please fill the field correctly', 'Close');
		}

	}

	/* Factory Pattern
	* Chart creation from a concrete factory through its base interface */
	async createMachineChart(facto: MChartFactory, machine_id: number, limit: number, time: string, chartName: string) {
		if (chartName === 'Machine Calibration Success History Chart') {
			this.m_calibSuccessHistory_chart = await facto.createChart(machine_id, limit, time);
		} else if (chartName === 'Avg Delta PE01 PE03') {
			this.m_avg_delta_pe01_pe03 = await facto.createChart(machine_id, limit, time);
		} else if (chartName === 'Total Filtration Time S0 S1') {
			this.m_total_filtration_time_s0_s1 = await facto.createChart(machine_id, limit, time);
		} else if (chartName === 'TTm Chart') {
			this.m_ttm_chart = await facto.createChart(machine_id, limit, time);
		} else if (chartName === 'Calibrators Chart') {
			this.m_calibrators_chart = await facto.createChart(machine_id, limit, time);
		} else if (chartName === 'Success Calibration Chart') {
			this.m_calibSuccess_chart = await facto.createChart(machine_id, limit, time);
		} else if (chartName === 'UG Chart') {
			this.m_ug_chart = await facto.createChart(machine_id, limit, time);
		} else if (chartName === 'Filling Time S0') {
			this.m_fillTime_S0 = await facto.createChart(machine_id, limit, time);
		} else if (chartName === 'Temperature Chart') {
			this.m_temperature_min_max_chart = await facto.createChart(machine_id, limit, time);
		} else if (chartName === 'Treshold Fluorescence Chart') {
			this.m_treshold_fluorescence_chart = await facto.createChart(machine_id, limit, time);
		}
	}



//#endregion

	openSnackBar(message, action) {
		this.snackbar.open(message, action, {duration: 5000});
	}

	openLegend(): void {
		this.dialog.open(MdLegendDialogComponent, {
			width: '80%',
			height: '80%'
		});
	}

	exportToCsv(chartName: string) {
		let temp = [];
		if (chartName === 'Machine Calibration Success History Chart') {
			temp = this.m_calibSuccessHistory_chart.chartData;
		} else if (chartName === 'Avg Delta PE01 PE03') {
			temp = this.m_avg_delta_pe01_pe03.chartData;
		} else if (chartName === 'Total Filtration Time S0 S1') {
			temp = this.m_total_filtration_time_s0_s1.chartData;
		} else if (chartName === 'TTm Chart') {
			temp = this.m_ttm_chart.chartData;
		} else if (chartName === 'Calibrators Chart') {
			temp = this.m_calibrators_chart.chartData;
		} else if (chartName === 'Success Calibration Chart') {
			temp = this.m_calibSuccess_chart.chartData;
		} else if (chartName === 'UG Chart') {
			temp = this.m_ug_chart.chartData;
		} else if (chartName === 'Filling Time S0'){
			temp = this. m_fillTime_S0.chartData;
		} else if (chartName === 'Temperature Chart') {
			temp = this.m_temperature_min_max_chart.chartData;
		} else if (chartName === 'Treshold Fluorescence Chart') {
			temp = this.m_treshold_fluorescence_chart.chartData;
		}

		const file = [];
		let i = 0;
		for (const t of temp) {
			const header = t.name;
			const data = t.y;
			const xaxis = t.x;
			data.forEach(element => {
				file.push([header, element, xaxis[i]]);
				i++;
			});
			i = 0;
		}

		const options = {
			filename: chartName,
			fieldSeparator: ',',
			quoteStrings: '"',
			decimalSeparator: '.',
			showLabels: true,
			showTitle: true,
			title: chartName,
			useTextFile: false,
			useBom: true,
			// useKeysA
			sHeaders: true,
		};
		const csvExporter = new ExportToCsv(options);

		csvExporter.generateCsv(file);
	}

	openChartFullscreen(chartName: string) {

		const selectedDate: Moment = this.machineSettingsForm.get('dateFormat').value;
		const time = moment(selectedDate).add(1, 'day').format('YYYY-MM-DD HH:mm:ss');
		if (chartName === 'Machine Calibration Success History Chart') {
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'Machine Calibration Success History Chart', this.machine.machine_id, this.machine.machine_tag, null, null, time, this.CurrentNumberOfSampleMachine])
			);
			window.open(url, '_blank');
		} else if (chartName === 'Avg Delta PE01 PE03') {
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'Avg Delta PE01 PE03', this.machine.machine_id, this.machine.machine_tag, null, null, time, this.CurrentNumberOfSampleMachine])
			);
			window.open(url, '_blank');
		} else if (chartName === 'Total Filtration Time S0 S1') {
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'Total Filtration Time S0 S1', this.machine.machine_id, this.machine.machine_tag, null, null, time, this.CurrentNumberOfSampleMachine])
			);
			window.open(url, '_blank');
		} else if (chartName === 'Calibrators Chart') {
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'Calibrators Chart', this.machine.machine_id, this.machine.machine_tag, null, null, time, this.CurrentNumberOfSampleMachine])
			);
			window.open(url, '_blank');
		} else if (chartName === 'TTm Chart') {
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'TTm Chart', this.machine.machine_id, this.machine.machine_tag, null, null, time, this.CurrentNumberOfSampleMachine])
			);
			window.open(url, '_blank');
		} else if (chartName === 'Success Calibration Chart') {
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'Success Calibration Chart', this.machine.machine_id, this.machine.machine_tag, null, null, time, this.CurrentNumberOfSampleMachine])
			);
			window.open(url, '_blank');
		} else if (chartName === 'UG Chart') {
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'UG Chart', this.machine.machine_id, this.machine.machine_tag, null, null, time, this.CurrentNumberOfSampleMachine])
			);
			window.open(url, '_blank');
		} else if (chartName === 'Filling Time S0') {
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'Filling Time S0', this.machine.machine_id, this.machine.machine_tag, null, null, time, this.CurrentNumberOfSampleMachine])
			);
			window.open(url, '_blank');
		} else if (chartName === 'Temperature Chart') {
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'Temperature Chart', this.machine.machine_id, this.machine.machine_tag, null, null, time, this.CurrentNumberOfSampleMachine])
			);
			window.open(url, '_blank');
		}
		else if (chartName === 'Treshold Fluorescence Chart') {
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'Treshold Fluorescence Chart', this.machine.machine_id, this.machine.machine_tag, null, null, time, this.CurrentNumberOfSampleMachine])
			);
			window.open(url, '_blank');
		}
	}

	sortData(sort: Sort) {
		const data = this.detailedAnalysisHistory.slice();
		if (!sort.active || sort.direction === '') {
			this.detailedAnalysisHistory = data;
			return;
		}
		this.dataSource = new MatTableDataSource(data.sort((a, b) => {
			const isAsc = sort.direction === 'asc';
			switch (sort.active) {
				case 'ttm':
					return compare(a.ttm, b.ttm, isAsc);
				case 'lotc01':
					return compare(a.lotc01, b.lotc01, isAsc);
				case 'c1MinusC2':
					return compare(a.c1MinusC2, b.c1MinusC2, isAsc);
				case 'calibration_success':
					return compare(a.calibration_success, b.calibration_success, isAsc);
				case 'sampling_time':
					return compare(a.sampling_time, b.sampling_time, isAsc);
				case 'analysis_time':
					return compare(a.analysis_time, b.analysis_time, isAsc);
				default:
					return 0;
			}
		}));
	}

	modifyChart(chart: CustomChartData, newX: [string, string], newY: [string, string]) {
		chart.chartLayout = {
			// items with a `name` attribute in template.images will be added to any
			// plot using this template
			images: [{
				source: '../../../../favicon.ico',
				xref: 'paper',
				yref: 'paper',
				x: 0.5,
				y: 0.5,
				xanchor: 'center',
				yanchor: 'middle',
				sizex: 0.7,
				sizey: 0.7,
				opacity: 0.1,
				layer: 'below'
			}],
			legend: {
				x: 0,
				y: 1.15,
				orientation: 'h',
				itemsizing: 'constant',
				itemwidth: 25
			},
			margin: {
				l: 35,
				r: 5,
				b: 50,
				t: 10
			},
			xaxis: {
				tickformat: '%d %b',
				tickangle: -45,
				showgrid: true,
				nticks: 36,
				showline: true,
				zeroline: false,
				mirror: true,
				hoverformat: '%Y-%m-%d %H:%M:%S',
				autorange: false,
				range: [new Date(newX[0]), new Date(newX[1])]
			},
			yaxis: {
				range: newY,
				showgrid: true,
				showline: true,
				zeroline: false,
				mirror: true
			},
			modebar: {
				color: '#000000',
				bgcolor: '#FFFFFF'
			}
		};
	}

	convertASCIItoChar(code: number) {
		if (code) {
			return String.fromCharCode(code);
		} else {
			return '';
		}
	}

	machineSettings(machine_id: number, machine_tag: string) {
		this.dialog.open(MachineLimitsComponent, {
			width: '50%',
			height: '70vh',
			data: {machine_id: machine_id, machine_tag: machine_tag}
		});
	}

	protected readonly Math = Math;
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
	return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

