<mat-card class="header" *ngIf="analysis_results">
	<mat-card-title fxLayout="row" fxLayoutGap="1.5%" fxLayoutAlign="start baseline">
		<button mat-mini-fab color="primary" (click)="back()">
			<mat-icon>keyboard_arrow_left</mat-icon>
		</button>
		<!--<h2>Tableau de bord de l'analyse : {{analysis_id}}</h2>-->
		<h2>Analysis dashboard : {{analysis_id}}</h2>
		<span fxFlex></span>
		<button mat-raised-button color="primary" (click)="reprocessCalculations()"
						[class.spinner]="reprocessingCalculations" [disabled]="reprocessingCalculations">Relaunch calculations
		</button>
	</mat-card-title>
</mat-card>

<mat-card class="information-card" *ngIf="analysis_results">
	<mat-card-header>
		<mat-card-title>Informations</mat-card-title>
	</mat-card-header>
	<mat-card-content *ngIf="analysis_results" fxLayout="row" fxLayoutGap="1.5%" fxLayoutAlign="space-arround start">
		<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" [ngStyle]="
                    analysis_results.machine_inlet_number == null?{'border-color':'#CCCCCC'} :
                    {'border-color': '#000000'}">
			<p>Id machine: </p>
			<button *ngIf="analysis_results.machine_tag; else defaultValue" mat-button [routerLink]="['/dashboards/machines', analysis_results.machine_tag]">
				{{analysis_results.machine_tag}}
			</button>
		</mat-card>

		<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" [ngStyle]="
                    analysis_results.machine_inlet_number == null?{'border-color':'#CCCCCC'} :
                    {'border-color': '#000000'}">
			<p>Inlet: </p>
			<p *ngIf="analysis_results.machine_inlet_number; else defaultValue">{{analysis_results.machine_inlet_number.value}}</p>
		</mat-card>

		<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" [ngStyle]="
                    analysis_results.sampling_time == null?{'border-color':'#CCCCCC'} :
                    {'border-color': '#000000'}">
			<p>Sampling time: </p>
			<p *ngIf="analysis_results.sampling_time; else defaultValue">{{analysis_results.sampling_time | date:'yyyy-MM-dd HH:mm:ss'}}</p>
		</mat-card>
		<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" [ngStyle]="
                    analysis_results.analysis_time == null?{'border-color':'#CCCCCC'} :
                    {'border-color': '#000000'}">
			<p>Analysis time: </p>
			<p *ngIf="analysis_results.analysis_time; else defaultValue">{{analysis_results.analysis_time | date:'yyyy-MM-dd HH:mm:ss'}}</p>
		</mat-card>

		<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" [ngStyle]="
                    analysis_results.sampling_name == null?{'border-color':'#CCCCCC'} :
                    {'border-color': '#000000'}">
			<p>Id sampling point: </p>
			<button *ngIf="analysis_results.sampling_name; else defaultValue" mat-button [routerLink]="['/dashboards/samplingPoint', analysis_results.sampling_id]">
				{{analysis_results.sampling_name}}
			</button>
		</mat-card>

		<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" matTooltip="{{getMissingDiagnostic()}}" [matTooltipPosition]="'below'" matTooltipClass="mat-tooltip"
							[ngStyle]="
                    missing_variable == null?{'border-color':'#CCCCCC'} :
                    {'border-color': '#000000'}">
			<p>Missing variables: </p>
			<p *ngIf="missing_variable != null; else defaultValue">{{missing_variable.length}}</p>
		</mat-card>

		<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" [ngStyle]="
                    analysis_results.quality_score == null?{'border-color':'#CCCCCC'} :
                    analysis_results.quality_score.value <= 50?{'border-color':'#E0242F'}:
                    analysis_results.quality_score.value <= 75?{'border-color':'#FFA500'}:
                    analysis_results.quality_score.value <= 85?{'border-color':'#F9D054'}:
                    {'border-color': '#008000'}">
			<p>Quality score: </p>
			<p *ngIf="analysis_results.quality_score; else defaultValue">{{analysis_results.quality_score.value}}</p>
		</mat-card>

		<ng-template #defaultValue>
			<p>No data</p>
		</ng-template>

	</mat-card-content>
</mat-card>

<mat-card class="result-card" *ngIf="analysis_results">
	<mat-card-header>
		<mat-card-title>Results</mat-card-title>
	</mat-card-header>
	<mat-card-content fxLayout="row" fxLayoutGap="1.5%" fxLayoutAlign="space-arround start">
		<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" [ngStyle]="
              analysis_results.result_value_ug_rounded == null?{'border-color':'#D5D5D5'} :
              analysis_results.result_value_ug_rounded.cl_level == 0?{'border-color': 'green'}:
              analysis_results.result_value_ug_rounded.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
              analysis_results.result_value_ug_rounded.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
              {'border-color': 'green'}">
			<p>UG/mL: </p>
			<p *ngIf="analysis_results.result_value_ug_rounded != null; else defaultValue">
				{{analysis_results.mrv_relation_symbol && analysis_results.mrv_relation_symbol.value == 60? '< ':''}}{{analysis_results.result_value_ug_rounded.value}}
			</p>
		</mat-card>

		<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" [ngStyle]="
              analysis_results.result_ug_optimized_rounded == null?{'border-color':'#D5D5D5'} :
              analysis_results.result_ug_optimized_rounded.value === null?{'border-color':'#E0242F'}:
              analysis_results.result_ug_optimized_rounded.cl_level == 0?{'border-color': 'green'}:
              analysis_results.result_ug_optimized_rounded.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
              analysis_results.result_ug_optimized_rounded.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
              {'border-color': 'green'}">
			<p>UG/mL (optimized): </p>
			<p *ngIf="analysis_results.result_ug_optimized_rounded != null; then optimized_result_value; else defaultValue">
			</p>
		</mat-card>
		<ng-template #optimized_result_value>
			<p *ngIf="analysis_results.result_ug_optimized_rounded.value != null; else optimized_result_value_null">{{analysis_results.result_value_ug_optimized.value < analysis_results.result_vmr.value?'< ':''}}{{analysis_results.result_ug_optimized_rounded.value}}</p>
		</ng-template>
		<ng-template #optimized_result_value_null>
			<p>Error</p>
		</ng-template>

		<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" [ngStyle]="
            analysis_results.result_c1_validitytest == null?{'border-color':'#CCCCCC'} :
            analysis_results.result_c1_validitytest.cl_level == 0?{'border-color': '#008000'}:
            analysis_results.result_c1_validitytest.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
            analysis_results.result_c1_validitytest.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
            {'border-color': '#008000'}">
			<p>Calibration success C1:</p>
			<p *ngIf="analysis_results.result_c1_validitytest; else defaultValue">{{analysis_results.result_c1_validitytest.value}}</p>
			<p *ngIf="analysis_results.result_imp_ug_c1_validity; else defaultValue">{{analysis_results.result_imp_ug_c1_validity.value}}</p>
		</mat-card>

		<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" [ngStyle]="
            analysis_results.result_c2_validitytest == null?{'border-color':'#CCCCCC'} :
            analysis_results.result_c2_validitytest.cl_level == 0?{'border-color': '#008000'}:
            analysis_results.result_c2_validitytest.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
            analysis_results.result_c2_validitytest.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
            {'border-color': '#008000'}">
			<p>Calibration success C2:</p>
			<p *ngIf="analysis_results.result_c2_validitytest; else defaultValue">{{analysis_results.result_c2_validitytest.value}}</p>
			<p *ngIf="analysis_results.result_imp_ug_c2_validity; else defaultValue">{{analysis_results.result_imp_ug_c2_validity.value}}</p>
		</mat-card>

		<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" [ngStyle]="
            analysis_results.result_calib_success == null?{'border-color':'#CCCCCC'} :
            analysis_results.result_calib_success.cl_level == 0?{'border-color': '#008000'}:
            analysis_results.result_calib_success.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
            analysis_results.result_calib_success.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
            {'border-color': '#008000'}">
			<p>Calibration success:</p>
			<p *ngIf="analysis_results.result_calib_success; else defaultValue">{{analysis_results.result_calib_success.value}}</p>
			<p *ngIf="analysis_results.result_imp_ug_calib_success; else defaultValue">{{analysis_results.result_imp_ug_calib_success.value}}</p>
		</mat-card>

		<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" [ngStyle]="
            analysis_results.result_ttm == null?{'border-color':'#CCCCCC'} :
            analysis_results.result_ttm.cl_level == 0?{'border-color': '#008000'}:
            analysis_results.result_ttm.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
            analysis_results.result_ttm.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
            {'border-color': '#008000'}">
			<p>TTm: </p>
			<p *ngIf="analysis_results.result_ttm; else defaultValue">{{analysis_results.result_ttm.value}}</p>
			<p *ngIf="analysis_results.improved_ttm; else defaultValue">{{analysis_results.improved_ttm.value}}</p>
		</mat-card>

		<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" [ngStyle]="
            analysis_results.result_tt1 == null?{'border-color':'#CCCCCC'} :
            analysis_results.result_tt1.cl_level == 0?{'border-color': '#008000'}:
            analysis_results.result_tt1.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
            analysis_results.result_tt1.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
            {'border-color': '#008000'}">
			<p>C1: </p>
			<p *ngIf="analysis_results.result_tt1; else defaultValue">{{analysis_results.result_tt1.value}}</p>
			<p *ngIf="analysis_results.fluo_advanced_tt_w1; else defaultValue">{{analysis_results.fluo_advanced_tt_w1.value}}</p>
		</mat-card>

		<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" [ngStyle]="
            analysis_results.result_tt4 == null?{'border-color':'#CCCCCC'} :
            analysis_results.result_tt4.cl_level == 0?{'border-color': '#008000'}:
            analysis_results.result_tt4.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
            analysis_results.result_tt4.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
            {'border-color': '#008000'}">
			<p>C2: </p>
			<p *ngIf="analysis_results.result_tt4; else defaultValue">{{analysis_results.result_tt4.value}}</p>
			<p *ngIf="analysis_results.fluo_advanced_tt_w4; else defaultValue">{{analysis_results.fluo_advanced_tt_w4.value}}</p>
		</mat-card>

		<ng-template #defaultValue>
			<p>No data</p>
		</ng-template>

	</mat-card-content>
</mat-card>

<mat-card class="diagnostic-card" *ngIf="analysis_results">
	<mat-card-header>
		<mat-card-title>Diagnostics</mat-card-title>
	</mat-card-header>
	<mat-card-content>

		<mat-card class="main-step-card">
			<mat-card-header>
				<mat-card-title>Sampling</mat-card-title>
			</mat-card-header>

			<mat-card-content>
				<div class="filling-time-s0-container" fxLayout="column" fxLayoutAlign="space-arround start">
					<!--<h2>Délai de remplissage de s0</h2>-->
					<h2>Time to fill s0</h2>
					<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                    analysis_results.samp_filltime_s0 == null?{'border-color':'#CCCCCC'} :
                    {'border-color': '#000000'}">
						<h2>Time</h2>
						<p *ngIf="analysis_results.samp_filltime_s0; else defaultValue">{{analysis_results.samp_filltime_s0.value}} s</p>
					</div>
				</div>
				<div class="pressure-seq-container">
					<h2 fxLayout="row" fxLayoutAlign="space-between baseline">
						<!--Graphique complet de la pression-->
						Full pressure graph
						<button mat-stroked-button color="primary" (click)="openFullscreenChart('Pressure Seq 2.4 Chart')">
							<mat-icon>insert_chart_outlined</mat-icon>
						</button>
					</h2>
				</div>

				<div class="avg-pressure" fxLayout="row-wrap" fxLayoutAlign="space-between start">
					<div class="avg-pressure-per-well-s0-container">
						<h2 fxLayout="row" fxLayoutAlign="space-between baseline">
							<!--Pressions moyennes S0-->
							Average pressures S0
							<button mat-stroked-button color="primary" (click)="openFullscreenChart('Pressure per well S0')">
								<mat-icon>insert_chart_outlined</mat-icon>
							</button>
						</h2>

						<div class="avg_pressure_per_well_s0_value" fxLayout="row" fxLayoutAlign="space-arround center"
								 fxLayoutGap="2.5%">
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                            analysis_results.filt_avgpressure_s0_w1 == null?{'border-color':'#CCCCCC'} :
                            analysis_results.filt_avgpressure_s0_w1.cl_level == 0?{'border-color': '#008000'}:
                            analysis_results.filt_avgpressure_s0_w1.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
                            analysis_results.filt_avgpressure_s0_w1.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
                            {'border-color': '#008000'}">
								<h3>Well 1</h3>
								<p *ngIf="analysis_results.filt_avgpressure_s0_w1; else defaultValue">{{analysis_results.filt_avgpressure_s0_w1.value}} Psi</p>
							</div>
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                            analysis_results.filt_avgpressure_s0_w2 == null?{'border-color':'#CCCCCC'} :
                            analysis_results.filt_avgpressure_s0_w2.cl_level == 0?{'border-color': '#008000'}:
                            analysis_results.filt_avgpressure_s0_w2.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
                            analysis_results.filt_avgpressure_s0_w2.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
                            {'border-color': '#008000'}">
								<h3>Well 2</h3>
								<p *ngIf="analysis_results.filt_avgpressure_s0_w2; else defaultValue">{{analysis_results.filt_avgpressure_s0_w2.value}} Psi</p>
							</div>
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                            analysis_results.filt_avgpressure_s0_w3 == null?{'border-color':'#CCCCCC'} :
                            analysis_results.filt_avgpressure_s0_w3.cl_level == 0?{'border-color': '#008000'}:
                            analysis_results.filt_avgpressure_s0_w3.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
                            analysis_results.filt_avgpressure_s0_w3.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
                            {'border-color': '#008000'}">
								<h3>Well 3</h3>
								<p *ngIf="analysis_results.filt_avgpressure_s0_w3; else defaultValue">{{analysis_results.filt_avgpressure_s0_w3.value}} Psi</p>
							</div>
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                            analysis_results.filt_avgpressure_s0_w4 == null?{'border-color':'#CCCCCC'} :
                            analysis_results.filt_avgpressure_s0_w4.cl_level == 0?{'border-color': '#008000'}:
                            analysis_results.filt_avgpressure_s0_w4.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
                            analysis_results.filt_avgpressure_s0_w4.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
                            {'border-color': '#008000'}">
								<h3>Well 4</h3>
								<p *ngIf="analysis_results.filt_avgpressure_s0_w4; else defaultValue">{{analysis_results.filt_avgpressure_s0_w4.value}} Psi</p>
							</div>
						</div>
					</div>

					<div class="avg-pressure-per-well-s1-container">
						<h2 fxLayout="row" fxLayoutAlign="space-between baseline">
							Average pressures S1
							<button mat-stroked-button color="primary" (click)="openFullscreenChart('Pressure per well S1')">
								<mat-icon>insert_chart_outlined</mat-icon>
							</button>
						</h2>

						<div class="avg_pressure_per_well_s1_value" fxLayout="row" fxLayoutAlign="space-arround center" fxLayoutGap="2.5%">
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                            analysis_results.filt_avgpressure_s1_w1 == null?{'border-color':'#CCCCCC'} :
                            analysis_results.filt_avgpressure_s1_w1.cl_level == 0?{'border-color': '#008000'}:
                            analysis_results.filt_avgpressure_s1_w1.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
                            analysis_results.filt_avgpressure_s1_w1.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
                            {'border-color': '#008000'}">
								<h3>Well 1</h3>
								<p *ngIf="analysis_results.filt_avgpressure_s1_w1; else defaultValue">{{analysis_results.filt_avgpressure_s1_w1.value}} Psi</p>
							</div>
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                            analysis_results.filt_avgpressure_s1_w2 == null?{'border-color':'#CCCCCC'} :
                            analysis_results.filt_avgpressure_s1_w2.cl_level == 0?{'border-color': '#008000'}:
                            analysis_results.filt_avgpressure_s1_w2.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
                            analysis_results.filt_avgpressure_s1_w2.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
                            {'border-color': '#008000'}">
								<h3>Well 2</h3>
								<p *ngIf="analysis_results.filt_avgpressure_s1_w2; else defaultValue">{{analysis_results.filt_avgpressure_s1_w2.value}} Psi</p>
							</div>
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                            analysis_results.filt_avgpressure_s1_w3 == null?{'border-color':'#CCCCCC'} :
                            analysis_results.filt_avgpressure_s1_w3.cl_level == 0?{'border-color': '#008000'}:
                            analysis_results.filt_avgpressure_s1_w3.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
                            analysis_results.filt_avgpressure_s1_w3.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
                            {'border-color': '#008000'}">
								<h3>Well 3</h3>
								<p *ngIf="analysis_results.filt_avgpressure_s1_w3; else defaultValue">{{analysis_results.filt_avgpressure_s1_w3.value}} Psi</p>
							</div>
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                            analysis_results.filt_avgpressure_s1_w4 == null?{'border-color':'#CCCCCC'} :
                            analysis_results.filt_avgpressure_s1_w4.cl_level == 0?{'border-color': '#008000'}:
                            analysis_results.filt_avgpressure_s1_w4.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
                            analysis_results.filt_avgpressure_s1_w4.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
                            {'border-color': '#008000'}">
								<h3>Well 4</h3>
								<p *ngIf="analysis_results.filt_avgpressure_s1_w4; else defaultValue">{{analysis_results.filt_avgpressure_s1_w4.value}} Psi</p>
							</div>
						</div>
					</div>

					<div class="avg-pressure-per-well-r-container">
						<h2 fxLayout="row" fxLayoutAlign="space-between baseline">
							Average air pressures
							<button mat-stroked-button color="primary" (click)="openFullscreenChart('Pressure per well Air')">
								<mat-icon>insert_chart_outlined</mat-icon>
							</button>
						</h2>
						<div class="avg_pressure_per_well_r_value" fxLayout="row" fxLayoutAlign="space-arround center" fxLayoutGap="2.5%">
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                          analysis_results.filt_avgpressure_air_w1 == null?{'border-color':'#CCCCCC'} :
                          analysis_results.filt_avgpressure_air_w1.cl_level == 0?{'border-color': '#008000'}:
                          analysis_results.filt_avgpressure_air_w1.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
                          analysis_results.filt_avgpressure_air_w1.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
                          {'border-color': '#008000'}">
								<h3>Well 1</h3>
								<p *ngIf="analysis_results.filt_avgpressure_air_w1; else defaultValue">{{analysis_results.filt_avgpressure_air_w1.value}} Psi</p>
							</div>
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                          analysis_results.filt_avgpressure_air_w2 == null?{'border-color':'#CCCCCC'} :
                          analysis_results.filt_avgpressure_air_w2.cl_level == 0?{'border-color': '#008000'}:
                          analysis_results.filt_avgpressure_air_w2.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
                          analysis_results.filt_avgpressure_air_w2.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
                          {'border-color': '#008000'}">
								<h3>Well 2</h3>
								<p *ngIf="analysis_results.filt_avgpressure_air_w2; else defaultValue">{{analysis_results.filt_avgpressure_air_w2.value}} Psi</p>
							</div>
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                          analysis_results.filt_avgpressure_air_w3 == null?{'border-color':'#CCCCCC'} :
                          analysis_results.filt_avgpressure_air_w3.cl_level == 0?{'border-color': '#008000'}:
                          analysis_results.filt_avgpressure_air_w3.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
                          analysis_results.filt_avgpressure_air_w3.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
                          {'border-color': '#008000'}">
								<h3>Well 3</h3>
								<p *ngIf="analysis_results.filt_avgpressure_air_w3; else defaultValue">{{analysis_results.filt_avgpressure_air_w3.value}} Psi</p>
							</div>
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                          analysis_results.filt_avgpressure_air_w4 == null?{'border-color':'#CCCCCC'} :
                          analysis_results.filt_avgpressure_air_w4.cl_level == 0?{'border-color': '#008000'}:
                          analysis_results.filt_avgpressure_air_w4.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
                          analysis_results.filt_avgpressure_air_w4.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
                          {'border-color': '#008000'}">
								<h3>Well 4</h3>
								<p *ngIf="analysis_results.filt_avgpressure_air_w4; else defaultValue">{{analysis_results.filt_avgpressure_air_w4.value}} Psi</p>
							</div>
						</div>
					</div>
				</div>


				<div class="filtration-time" fxLayout="row-wrap" fxLayoutAlign="space-between start">
					<div class="filtration-per-well-container">
						<h2 fxLayout="row" fxLayoutAlign="space-between baseline">
							Filtration time S0
						</h2>
						<div class="filtration_time_per_well_s0_value" fxLayout="row" fxLayoutAlign="space-arround center" fxLayoutGap="2.5%">
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                          analysis_results.filt_duration_s0_w1 == null?{'border-color':'#CCCCCC'} :
                          {'border-color': '#000000'}">
								<h3>Well 1</h3>
								<p *ngIf="analysis_results.filt_duration_s0_w1; else defaultValue">{{analysis_results.filt_duration_s0_w1.value}} s</p>
							</div>
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                          analysis_results.filt_duration_s0_w2 == null?{'border-color':'#CCCCCC'} :
                          {'border-color': '#000000'}">
								<h3>Well 2</h3>
								<p *ngIf="analysis_results.filt_duration_s0_w2; else defaultValue">{{analysis_results.filt_duration_s0_w2.value}} s</p>
							</div>
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                          analysis_results.filt_duration_s0_w3 == null?{'border-color':'#CCCCCC'} :
                          {'border-color': '#000000'}">
								<h3>Well 3</h3>
								<p *ngIf="analysis_results.filt_duration_s0_w3; else defaultValue">{{analysis_results.filt_duration_s0_w3.value}} s</p>
							</div>
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                          analysis_results.filt_duration_s0_w4 == null?{'border-color':'#CCCCCC'} :
                          {'border-color': '#000000'}">
								<h3>Well 4</h3>
								<p *ngIf="analysis_results.filt_duration_s0_w4; else defaultValue">{{analysis_results.filt_duration_s0_w4.value}} s</p>
							</div>
						</div>
					</div>

					<div class="filtration-per-well-container">
						<h2 fxLayout="row" fxLayoutAlign="space-between baseline">
							Filtration time S1
						</h2>
						<div class="filtration_time_per_well_s1_value" fxLayout="row" fxLayoutAlign="space-arround center" fxLayoutGap="2.5%">
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                          analysis_results.filt_duration_s1_w1 == null?{'border-color':'#CCCCCC'} :
                          {'border-color': '#000000'}">
								<h3>Well 1</h3>
								<p *ngIf="analysis_results.filt_duration_s1_w1; else defaultValue">{{analysis_results.filt_duration_s1_w1.value}} s</p>
							</div>
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                          analysis_results.filt_duration_s1_w2 == null?{'border-color':'#CCCCCC'} :
                          {'border-color': '#000000'}">
								<h3>Well 2</h3>
								<p *ngIf="analysis_results.filt_duration_s1_w2; else defaultValue">{{analysis_results.filt_duration_s1_w2.value}} s</p>
							</div>
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                          analysis_results.filt_duration_s1_w3 == null?{'border-color':'#CCCCCC'} :
                          {'border-color': '#000000'}">
								<h3>Well 3</h3>
								<p *ngIf="analysis_results.filt_duration_s1_w3; else defaultValue">{{analysis_results.filt_duration_s1_w3.value}} s</p>
							</div>
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                          analysis_results.filt_duration_s1_w4 == null?{'border-color':'#CCCCCC'} :
                          {'border-color': '#000000'}">
								<h3>Well 4</h3>
								<p *ngIf="analysis_results.filt_duration_s1_w4; else defaultValue">{{analysis_results.filt_duration_s1_w4.value}} s</p>
							</div>
						</div>
					</div>

					<div class="filtration-per-well-container">
						<h2 fxLayout="row" fxLayoutAlign="space-between baseline">
							Air filtration time
						</h2>
						<div class="filtration_time_per_well_r_value" fxLayout="row" fxLayoutAlign="space-arround center" fxLayoutGap="2.5%">
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                          analysis_results.filt_duration_air_w1 == null?{'border-color':'#CCCCCC'} :
                          {'border-color': '#000000'}">
								<h3>Well 1</h3>
								<p *ngIf="analysis_results.filt_duration_air_w1; else defaultValue">{{analysis_results.filt_duration_air_w1.value}} s</p>
							</div>
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                          analysis_results.filt_duration_air_w2 == null?{'border-color':'#CCCCCC'} :
                          {'border-color': '#000000'}">
								<h3>Well 2</h3>
								<p *ngIf="analysis_results.filt_duration_air_w2; else defaultValue">{{analysis_results.filt_duration_air_w2.value}} s</p>
							</div>
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                          analysis_results.filt_duration_air_w3 == null?{'border-color':'#CCCCCC'} :
                          {'border-color': '#000000'}">
								<h3>Well 3</h3>
								<p *ngIf="analysis_results.filt_duration_air_w3; else defaultValue">{{analysis_results.filt_duration_air_w3.value}} s</p>
							</div>
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                          analysis_results.filt_duration_air_w4 == null?{'border-color':'#CCCCCC'} :
                          {'border-color': '#000000'}">
								<h3>Well 4</h3>
								<p *ngIf="analysis_results.filt_duration_air_w4; else defaultValue">{{analysis_results.filt_duration_air_w4.value}} s</p>
							</div>
						</div>
					</div>
				</div>

				<div class="avg-delta-pe01-pe03-container">
					<h2 fxLayout="row" fxLayoutAlign="space-between baseline">
						Average delta at E01-PE-01 and E01-PE-02 pre-filters
					</h2>
					<div class="avg_delta_pe01_pe03_value" fxLayout="row" fxLayoutAlign="space-arround center" fxLayoutGap="2.5%">
						<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                        analysis_results.prefilt_mean_delta_pe01_pe03 == null?{'border-color':'#CCCCCC'} :
                        analysis_results.prefilt_mean_delta_pe01_pe03.cl_level == 0?{'border-color': '#008000'}:
                        analysis_results.prefilt_mean_delta_pe01_pe03.cl_level % 2 != 0 ?{'border-color':'#F9D054'} :
                        analysis_results.prefilt_mean_delta_pe01_pe03.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
                        {'border-color': '#008000'}">
							<h3>E01-PE-01 - E01-PE-02</h3>
							<p *ngIf="analysis_results.prefilt_mean_delta_pe01_pe03; else defaultValue">{{analysis_results.prefilt_mean_delta_pe01_pe03.value}} Psi</p>
						</div>
					</div>
				</div>


				<div class="max-pressure-pe04-container">
					<h2 fxLayout="row" fxLayoutAlign="space-between baseline">
						<!--Pression d'air maximum PE04 pendant Init Fluid post injection S2-->
						Maximum air pressure PE04 during Init Fluid post injection S2
					</h2>

					<div class="max_pressure_pe04_value" fxLayout="row" fxLayoutAlign="space-arround center" fxLayoutGap="2.5%">
						<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                        analysis_results.rinse_max_pe04_w1 == null?{'border-color':'#CCCCCC'} :
                        {'border-color': '#000000'}">
							<h3>Position 1</h3>
							<p *ngIf="analysis_results.rinse_max_pe04_w1; else defaultValue">{{analysis_results.rinse_max_pe04_w1.value}} Psi</p>
						</div>
						<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                        analysis_results.rinse_max_pe04_w2 == null?{'border-color':'#CCCCCC'} :
                        {'border-color': '#000000'}">
							<h3>Position 2</h3>
							<p *ngIf="analysis_results.rinse_max_pe04_w2; else defaultValue">{{analysis_results.rinse_max_pe04_w2.value}} Psi</p>
						</div>
						<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                        analysis_results.rinse_max_pe04_w3 == null?{'border-color':'#CCCCCC'} :
                        {'border-color': '#000000'}">
							<h3>Position 3</h3>
							<p *ngIf="analysis_results.rinse_max_pe04_w3; else defaultValue">{{analysis_results.rinse_max_pe04_w3.value}} Psi</p>
						</div>
						<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                        analysis_results.rinse_max_pe04_w4 == null?{'border-color':'#CCCCCC'} :
                        {'border-color': '#000000'}">
							<h3>Position 4</h3>
							<p *ngIf="analysis_results.rinse_max_pe04_w4; else defaultValue">{{analysis_results.rinse_max_pe04_w4.value}} Psi</p>
						</div>
					</div>
				</div>

			</mat-card-content>
		</mat-card>

		<mat-card class="main-step-card">
			<mat-card-header>
				<mat-card-title>Analysis</mat-card-title>
			</mat-card-header>

			<mat-card-content class="analysis-result-card">
				<div class="fluo_container" fxLayout="row-wrap" fxLayoutAlign="start start" fxLayoutGap="2.5%">

					<div class="fluo-test-container">
						<h2 fxLayout="row" fxLayoutAlign="space-between baseline">
							Delivery test
							<button mat-stroked-button color="primary" (click)="openFullscreenChart('Fluo Data Chart')">
								<mat-icon>insert_chart_outlined</mat-icon>
							</button>
						</h2>
						<div class="fluo_test_value" fxLayout="row" fxLayoutAlign="space-arround center" fxLayoutGap="2.5%">

							<div class="empty-indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                        analysis_results.fluo_s4droptest_w1 == null?{'border-color':'#CCCCCC'} :
                        analysis_results.fluo_s4droptest_w1.cl_level == 0?{'border-color': '#008000'}:
                        analysis_results.fluo_s4droptest_w1.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
                        {'border-color': '#CCCCCC'}">
								<h3>ue01</h3>
								<p *ngIf="analysis_results.fluo_s4droptest_w1; else defaultValue"> {{analysis_results.fluo_s4droptest_w1.value}}</p>
							</div>
							<div class="empty-indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                        analysis_results.fluo_s4droptest_w2 == null?{'border-color':'#CCCCCC'} :
                        analysis_results.fluo_s4droptest_w2.cl_level == 0?{'border-color': '#008000'}:
                        analysis_results.fluo_s4droptest_w2.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
                        {'border-color': '#CCCCCC'}">
								<h3>ue02</h3>
								<p *ngIf="analysis_results.fluo_s4droptest_w2; else defaultValue"> {{analysis_results.fluo_s4droptest_w2.value}}</p>
							</div>
							<div class="empty-indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                        analysis_results.fluo_s4droptest_w3 == null?{'border-color':'#CCCCCC'} :
                        analysis_results.fluo_s4droptest_w3.cl_level == 0?{'border-color': '#008000'}:
                        analysis_results.fluo_s4droptest_w3.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
                        {'border-color': '#CCCCCC'}">
								<h3>ue03</h3>
								<p *ngIf="analysis_results.fluo_s4droptest_w3; else defaultValue">{{analysis_results.fluo_s4droptest_w3.value}}</p>
							</div>
							<div class="empty-indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                        analysis_results.fluo_s4droptest_w4 == null?{'border-color':'#CCCCCC'} :
                        analysis_results.fluo_s4droptest_w4.cl_level == 0?{'border-color': '#008000'}:
                        analysis_results.fluo_s4droptest_w4.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
                        {'border-color': '#CCCCCC'}">
								<h3>ue04</h3>
								<p *ngIf="analysis_results.fluo_s4droptest_w4; else defaultValue">{{analysis_results.fluo_s4droptest_w4.value}}</p>
							</div>
						</div>
					</div>

					<div class="fluo-test-container">
						<!--<h2>Débattement</h2>-->
						<h2>Signal amplitude</h2>
						<div fxLayout="row" fxLayoutAlign="space-arround center" fxLayoutGap="2.5%">
							<div class="indicator" [ngStyle]="
                        analysis_results.fluo_difference_w1 == null?{'border-color':'#CCCCCC'} :
                        {'border-color': '#000000'}">
								<h3>ue01</h3>
								<p *ngIf="analysis_results.fluo_difference_w1; else defaultValue"> {{analysis_results.fluo_difference_w1.value}}</p>
							</div>
							<div class="indicator" [ngStyle]="
                      analysis_results.fluo_difference_w2 == null?{'border-color':'#CCCCCC'} :
                      {'border-color': '#000000'}">
								<h3>ue02</h3>
								<p *ngIf="analysis_results.fluo_difference_w2; else defaultValue">{{analysis_results.fluo_difference_w2.value}}</p>
							</div>
							<div class="indicator" [ngStyle]="
                        analysis_results.fluo_difference_w3 == null?{'border-color':'#CCCCCC'} :
                       {'border-color': '#000000'}">
								<h3>ue03</h3>
								<p *ngIf="analysis_results.fluo_difference_w3; else defaultValue">{{analysis_results.fluo_difference_w3.value}}</p>
							</div>
							<div class="indicator" [ngStyle]="
                      analysis_results.fluo_difference_w4 == null?{'border-color':'#CCCCCC'} :
                      {'border-color': '#000000'}">
								<h3>ue04</h3>
								<p *ngIf="analysis_results.fluo_difference_w4; else defaultValue">{{analysis_results.fluo_difference_w4.value}}</p>
							</div>
						</div>
					</div>
				</div>

				<div class="avg-pressure" fxLayout="row-wrap" fxLayoutAlign="start start" fxLayoutGap="2.5%">
					<div class="temps-sortie-container">
						<h2 fxLayout="row" fxLayoutAlign="start start">
							<!--Temps de sorties-->
							Threshold time (TT)
						</h2>
						<div class="fluo_test_value" fxLayout="row" fxLayoutAlign="space-arround center" fxLayoutGap="2.5%">
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                        analysis_results.result_tt1 == null?{'border-color':'#CCCCCC'} :
                        analysis_results.result_tt1.cl_level == 0?{'border-color': '#008000'}:
                        analysis_results.result_tt1.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
                        {'border-color': '#CCCCCC'}">
								<h3>Well 1</h3>
								<p *ngIf="analysis_results.result_tt1; else defaultValue">{{analysis_results.result_tt1.value}} s</p>
							</div>
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                        analysis_results.result_tt2 == null?{'border-color':'#CCCCCC'} :
                        analysis_results.result_tt2.cl_level == 0?{'border-color': '#008000'}:
                        analysis_results.result_tt2.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
                        {'border-color': '#CCCCCC'}">
								<h3>Well 2</h3>
								<p *ngIf="analysis_results.result_tt2; else defaultValue">{{analysis_results.result_tt2.value}} s</p>
							</div>
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                        analysis_results.result_tt3 == null?{'border-color':'#CCCCCC'} :
                        analysis_results.result_tt3.cl_level == 0?{'border-color': '#008000'}:
                        analysis_results.result_tt3.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
                        {'border-color': '#CCCCCC'}">
								<h3>Well 3</h3>
								<p *ngIf="analysis_results.result_tt3; else defaultValue">{{analysis_results.result_tt3.value}} s</p>
							</div>
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                        analysis_results.result_tt4 == null?{'border-color':'#CCCCCC'} :
                        analysis_results.result_tt4.cl_level == 0?{'border-color': '#008000'}:
                        analysis_results.result_tt4.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
                        {'border-color': '#CCCCCC'}">
								<h3>Well 4</h3>
								<p *ngIf="analysis_results.result_tt4; else defaultValue">{{analysis_results.result_tt4.value}} s</p>
							</div>
						</div>
					</div>

					<div class="temps-sortie-container">
						<h2 fxLayout="row" fxLayoutAlign="start start">
							<!--Valeurs améliorées du temps de sortie-->
							Improved values of exit time
						</h2>
						<div class="fluo_test_value" fxLayout="row" fxLayoutAlign="space-arround center" fxLayoutGap="2.5%">
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                        analysis_results.fluo_advanced_tt_w1 == null?{'border-color':'#CCCCCC'} :
                        analysis_results.fluo_advanced_tt_w1.cl_level == 0?{'border-color': '#008000'}:
                        analysis_results.fluo_advanced_tt_w1.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
                        {'border-color': '#CCCCCC'}">
								<h3>Well 1</h3>
								<p *ngIf="analysis_results.fluo_advanced_tt_w1; else defaultValue">{{analysis_results.fluo_advanced_tt_w1.value}} s</p>
							</div>
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                        analysis_results.fluo_advanced_tt_w2 == null?{'border-color':'#CCCCCC'} :
                        analysis_results.fluo_advanced_tt_w2.cl_level == 0?{'border-color': '#008000'}:
                        analysis_results.fluo_advanced_tt_w2.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
                        {'border-color': '#CCCCCC'}">
								<h3>Well 2</h3>
								<p *ngIf="analysis_results.fluo_advanced_tt_w2; else defaultValue">{{analysis_results.fluo_advanced_tt_w2.value}} s</p>
							</div>
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                        analysis_results.fluo_advanced_tt_w3 == null?{'border-color':'#CCCCCC'} :
                        analysis_results.fluo_advanced_tt_w3.cl_level == 0?{'border-color': '#008000'}:
                        analysis_results.fluo_advanced_tt_w3.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
                        {'border-color': '#CCCCCC'}">
								<h3>Well 3</h3>
								<p *ngIf="analysis_results.fluo_advanced_tt_w3; else defaultValue">{{analysis_results.fluo_advanced_tt_w3.value}} s</p>
							</div>
							<div class="indicator" fxLayout="column" fxLayoutAlign="space-arround center" [ngStyle]="
                        analysis_results.fluo_advanced_tt_w4 == null?{'border-color':'#CCCCCC'} :
                        analysis_results.fluo_advanced_tt_w4.cl_level == 0?{'border-color': '#008000'}:
                        analysis_results.fluo_advanced_tt_w4.cl_level % 2 == 0 ?{'border-color':'#E0242F'} :
                        {'border-color': '#CCCCCC'}">
								<h3>Well 4</h3>
								<p *ngIf="analysis_results.fluo_advanced_tt_w4; else defaultValue">{{analysis_results.fluo_advanced_tt_w4.value}} s</p>
							</div>
						</div>
					</div>
				</div>

				<ng-template #defaultValue>
					<p>No data</p>
				</ng-template>

			</mat-card-content>
		</mat-card>

		<mat-card class="main-step-card">
			<mat-card-header>
				<mat-card-title>™ Calling</mat-card-title>
			</mat-card-header>

			<mat-card-content class="tm-calling-card">
				<div class="tm-calling-container">
					<h2 fxLayout="row" fxLayoutAlign="space-between baseline">Melting temperatures
						<button mat-stroked-button color="primary" (click)="openFullscreenChart('TM Calling')" style="position: relative">
							<mat-icon>insert_chart_outlined</mat-icon>
						</button>
					</h2>
					<table class="styled-table">
						<thead>
						<tr>
							<th></th>
							<th>Well 1</th>
							<th>Well 2</th>
							<th>Well 3</th>
							<th>Well 4</th>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td>Interspecies ratio</td>
							<td *ngIf="analysis_results.fluo_melting_temp_ratio_w1; else defaultTableValue">{{ analysis_results.fluo_melting_temp_ratio_w1.value }}</td>
							<td *ngIf="analysis_results.fluo_melting_temp_ratio_w2; else defaultTableValue">{{ analysis_results.fluo_melting_temp_ratio_w2.value }}</td>
							<td *ngIf="analysis_results.fluo_melting_temp_ratio_w3; else defaultTableValue">{{ analysis_results.fluo_melting_temp_ratio_w3.value }}</td>
							<td *ngIf="analysis_results.fluo_melting_temp_ratio_w4; else defaultTableValue">{{ analysis_results.fluo_melting_temp_ratio_w4.value }}</td>
						</tr>
						<tr>
							<td>Species A (°C)</td>
							<td *ngIf="analysis_results.fluo_melting_temp_eA_w1; else defaultTableValue">{{ analysis_results.fluo_melting_temp_eA_w1.value }}</td>
							<td *ngIf="analysis_results.fluo_melting_temp_eA_w2; else defaultTableValue">{{ analysis_results.fluo_melting_temp_eA_w2.value }}</td>
							<td *ngIf="analysis_results.fluo_melting_temp_eA_w3; else defaultTableValue">{{ analysis_results.fluo_melting_temp_eA_w3.value }}</td>
							<td *ngIf="analysis_results.fluo_melting_temp_eA_w4; else defaultTableValue">{{ analysis_results.fluo_melting_temp_eA_w4.value }}</td>
						</tr>
						<tr>
							<td>Species B (°C)</td>
							<td *ngIf="analysis_results.fluo_melting_temp_eB_w1; else defaultTableValue">{{ analysis_results.fluo_melting_temp_eB_w1.value }}</td>
							<td *ngIf="analysis_results.fluo_melting_temp_eB_w2; else defaultTableValue">{{ analysis_results.fluo_melting_temp_eB_w2.value }}</td>
							<td *ngIf="analysis_results.fluo_melting_temp_eB_w3; else defaultTableValue">{{ analysis_results.fluo_melting_temp_eB_w3.value }}</td>
							<td *ngIf="analysis_results.fluo_melting_temp_eB_w4; else defaultTableValue">{{ analysis_results.fluo_melting_temp_eB_w4.value }}</td>
						</tr>
						<tr>
							<td>Difference A</td>
							<td [style]="getTmCallingFlag(analysis_results.fluo_melting_temp_eA_w1.value,this.tm_calling_avg) == true ? {'color': '#cc0000'} : {'color': '#9a9a9a'}"
							*ngIf="analysis_results.fluo_melting_temp_eA_w1; else defaultTableValue">
								{{analysis_results.fluo_melting_temp_eA_w1.value - this.tm_calling_avg | number:'1.2-2'}}</td>

							<td [style]="getTmCallingFlag(analysis_results.fluo_melting_temp_eA_w2.value,this.tm_calling_avg) == true ? {'color': '#cc0000'} : {'color': '#000000'}"
							*ngIf="analysis_results.fluo_melting_temp_eA_w2; else defaultTableValue">
								{{analysis_results.fluo_melting_temp_eA_w2.value - this.tm_calling_avg | number:'1.2-2' }}</td>

							<td [style]="getTmCallingFlag(analysis_results.fluo_melting_temp_eA_w3.value,this.tm_calling_avg) == true ? {'color': '#cc0000'} : {'color': '#000000'}"
									*ngIf="analysis_results.fluo_melting_temp_eA_w3; else defaultTableValue">
								{{analysis_results.fluo_melting_temp_eA_w3.value - this.tm_calling_avg | number:'1.2-2' }}</td>

							<td [style]="getTmCallingFlag(analysis_results.fluo_melting_temp_eA_w4.value,this.tm_calling_avg) == true ? {'color': '#cc0000'} : {'color': '#9a9a9a'}"
									*ngIf="analysis_results.fluo_melting_temp_eA_w4; else defaultTableValue">
								{{analysis_results.fluo_melting_temp_eA_w4.value - this.tm_calling_avg | number:'1.2-2' }}</td>
						</tr>

						<tr>
							<td>Difference B</td>
							<td [style]="getTmCallingFlag(analysis_results.fluo_melting_temp_eB_w1.value,this.tm_calling_avg) == true ? {'color': '#cc0000'} : {'color': '#9a9a9a'}"
									*ngIf="analysis_results.fluo_melting_temp_eB_w1; else defaultTableValue">
								{{analysis_results.fluo_melting_temp_eB_w1.value - this.tm_calling_avg | number:'1.2-2'}}</td>

							<td [style]="getTmCallingFlag(analysis_results.fluo_melting_temp_eB_w2.value,this.tm_calling_avg) == true ? {'color': '#cc0000'} : {'color': '#000000'}"
									*ngIf="analysis_results.fluo_melting_temp_eB_w2; else defaultTableValue">
								{{analysis_results.fluo_melting_temp_eB_w2.value - this.tm_calling_avg | number:'1.2-2' }}</td>

							<td [style]="getTmCallingFlag(analysis_results.fluo_melting_temp_eB_w3.value,this.tm_calling_avg) == true ? {'color': '#cc0000'} : {'color': '#000000'}"
									*ngIf="analysis_results.fluo_melting_temp_eB_w3; else defaultTableValue">
								{{analysis_results.fluo_melting_temp_eB_w3.value - this.tm_calling_avg | number:'1.2-2' }}</td>
							<td [style]="getTmCallingFlag(analysis_results.fluo_melting_temp_eB_w4.value,this.tm_calling_avg) == true ? {'color': '#cc0000'} : {'color': '#9a9a9a'}"
									*ngIf="analysis_results.fluo_melting_temp_eB_w4; else defaultTableValue">
								{{analysis_results.fluo_melting_temp_eB_w4.value - this.tm_calling_avg | number:'1.2-2' }}</td>
						</tr>
						</tbody>
					</table>
				</div>

				<ng-template #defaultTableValue>
					<td>No data</td>
				</ng-template>

			</mat-card-content>
		</mat-card>
	</mat-card-content>
</mat-card>

<mat-card class="additional-information-card" *ngIf="analysis_results">
	<mat-card-header>
		<mat-card-title>Additional Informations</mat-card-title>
	</mat-card-header>
	<mat-card-content *ngIf="analysis_results" fxLayout="row" fxLayoutGap="1.5%" fxLayoutAlign="space-arround start">
		<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" [ngStyle]="
                    analysis_results.ambiant_temperature_min == null?{'border-color':'#CCCCCC'} :
                    {'border-color': '#000000'}">
			<p>Minimum Temperature: </p>
			<p *ngIf="analysis_results.ambiant_temperature_min; else defaultValue">{{analysis_results.ambiant_temperature_min.value}}</p>
		</mat-card>

		<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" [ngStyle]="
                    analysis_results.ambiant_temperature_max == null?{'border-color':'#CCCCCC'} :
                    {'border-color': '#000000'}">
			<p>Maximum Temperature: </p>
			<p *ngIf="analysis_results.ambiant_temperature_max; else defaultValue">{{analysis_results.ambiant_temperature_max.value}}</p>
		</mat-card>
		<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" [ngStyle]="
                    !analysis_results.has_error ? {'border-color':'#CCCCCC'} : {'border-color': '#000000'}">
			<p>Errors: </p>
			<mat-label *ngIf="!analysis_results.has_error">-</mat-label>
			<mat-label *ngIf="analysis_results.has_error">{{analysis_results.errors}}</mat-label>

		</mat-card>
		<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" [ngStyle]="
                    !analysis_results.analysis_tag ? {'border-color':'#CCCCCC'} : {'border-color': '#000000'}">
			<p>Analysis Tags: </p>
			<p *ngIf="analysis_results.analysis_tag; else defaultValue">{{analysis_results.analysis_tag}}</p>
		</mat-card>

		<ng-template #defaultValue>
			<p>No data</p>
		</ng-template>

	</mat-card-content>
</mat-card>
