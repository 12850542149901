import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardAnalysisService} from './dashboard-analysis.service';
import {MatSnackBar} from '@angular/material/snack-bar';

import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {NavigationService} from "../navigation-service.service";
import {NavbarService} from "../header/navbar.service";

registerLocaleData(localeFr, 'fr');

@Component({
	selector: 'app-dashboard-analysis',
	templateUrl: './dashboard-analysis.component.html',
	styleUrls: ['./dashboard-analysis.component.css']
})
export class DashboardAnalysisComponent implements OnInit {

	public analysis_id: number;
	public machine_id: number;
	public analysis_results: any;
	public missing_variable: any = [];
	public tm_calling_avg: number;

	reprocessingCalculations: boolean = false;
	tm_calling_flag: boolean = false;

	constructor(
		private route: ActivatedRoute,
		private service: DashboardAnalysisService,
		private snackbar: MatSnackBar,
		private router: Router,
		private navigation: NavigationService,
		public nav: NavbarService,
	) {
	}

	async ngOnInit() {
		this.analysis_id = +this.route.snapshot.paramMap.get('analysisId');
		this.analysis_results = await this.service.getMachineAnalysisFullDetail(this.analysis_id);
		this.missing_variable = await this.service.getAnalysisMissingVariables(this.analysis_id);
		this.machine_id = this.analysis_results.machine_id;
		this.machine_id = this.analysis_results.machine_id;

		if (this.analysis_results.result_vmr==null) {
			this.analysis_results.result_vmr = {"value": 5 / this.analysis_results.result_volume.value}
		}
		if (this.analysis_results.fluo_melting_temp_ratio_w1 &&
				this.analysis_results.fluo_melting_temp_ratio_w4 &&
				this.analysis_results.fluo_melting_temp_eA_w1 &&
				this.analysis_results.fluo_melting_temp_eB_w1 &&
				this.analysis_results.fluo_melting_temp_eA_w4 &&
			  this.analysis_results.fluo_melting_temp_eB_w4
		) {
			this.tm_calling_avg = this.calculateAverage(this.analysis_results.fluo_melting_temp_ratio_w1.value, this.analysis_results.fluo_melting_temp_ratio_w4.value,
				this.analysis_results.fluo_melting_temp_eA_w1.value, this.analysis_results.fluo_melting_temp_eB_w1.value,
				this.analysis_results.fluo_melting_temp_eA_w4.value, this.analysis_results.fluo_melting_temp_eB_w4.value);
		}

		if (this.analysis_results.result_value_ug_optimized && this.analysis_results.result_value_ug_optimized.meta_info) {
			this.analysis_results.analysis_tag = "analysis: " + this.analysis_results.result_value_ug_optimized.meta_info.analyse.replace(/^./, '').replace(/\./g, ', ') + "\n" + "w1: " + this.analysis_results.result_value_ug_optimized.meta_info.w1.replace(/^./, '').replace(/\./g, ', ') + "\n" + "w2: " + this.analysis_results.result_value_ug_optimized.meta_info.w2.replace(/^./, '').replace(/\./g, ', ') + "\n" + "w3: " + this.analysis_results.result_value_ug_optimized.meta_info.w3.replace(/^./, '').replace(/\./g, ', ') + "\n" + "w4: " + this.analysis_results.result_value_ug_optimized.meta_info.w4.replace(/^./, '').replace(/\./g, ', ');
		}

		this.analysis_results.has_error = false;
		let error_list = [];
		if (this.analysis_results.result_test_livraison_w1 && this.analysis_results.result_test_livraison_w1.value === 0) {
			error_list.push('Error: W1 Failed delivery test');
		}
		if (this.analysis_results.result_test_livraison_w2 && this.analysis_results.result_test_livraison_w2.value === 0) {
			error_list.push('Error: W2 Failed delivery test');
		}
		if (this.analysis_results.result_test_livraison_w3 && this.analysis_results.result_test_livraison_w3.value === 0) {
			error_list.push('Error: W3 Failed delivery test');
		}
		if (this.analysis_results.result_test_livraison_w4 && this.analysis_results.result_test_livraison_w4.value === 0) {
			error_list.push('Error: W4 Failed delivery test');
		}
		if (this.analysis_results.result_test_debatement_w1 && this.analysis_results.result_test_debatement_w1.value === 0) {
			error_list.push('Error: W1 Failed amplitude test');
		}
		if (this.analysis_results.result_test_debatement_w2 && this.analysis_results.result_test_debatement_w2.value === 0) {
			error_list.push('Error: W2 Failed amplitude test');
		}
		if (this.analysis_results.result_test_debatement_w3 && this.analysis_results.result_test_debatement_w3.value === 0) {
			error_list.push('Error: W3 Failed amplitude test');
		}
		if (this.analysis_results.result_test_debatement_w4 && this.analysis_results.result_test_debatement_w4.value === 0) {
			error_list.push('Error: W4 Failed amplitude test');
		}
		if (this.analysis_results.result_test_derivative_treshold_w1 && this.analysis_results.result_test_derivative_treshold_w1.value === 0) {
			error_list.push('Error: W1 Failed derivative threshold test');
		}
		if (this.analysis_results.result_test_derivative_treshold_w2 && this.analysis_results.result_test_derivative_treshold_w2.value === 0) {
			error_list.push('Error: W2 Failed derivative threshold test');
		}
		if (this.analysis_results.result_test_derivative_treshold_w3 && this.analysis_results.result_test_derivative_treshold_w3.value === 0) {
			error_list.push('Error: W3 Failed derivative threshold test');
		}
		if (this.analysis_results.result_test_derivative_treshold_w4 && this.analysis_results.result_test_derivative_treshold_w4.value === 0) {
			error_list.push('Error: W4 Failed derivative threshold test');
		}
		if (this.analysis_results.volume_valid && this.analysis_results.volume_valid.value === 0) {
			error_list.push('ERR_VOLUME_INVALID: Invalid volume value');
		}
		if (this.analysis_results.ttm_valid && this.analysis_results.ttm_valid.value === 0) {
			error_list.push('ERR_TTM_INVALID: Invalid TTm value');
		}
		if (this.analysis_results.split_vmr_valid && this.analysis_results.split_vmr_valid.value === 0) {
			error_list.push('ERR_SPLIT_VMR: Invalid UG difference between sample');
		}
		if (this.analysis_results.log_difference_valid && this.analysis_results.log_difference_valid.value === 0) {
			error_list.push('WARNING_UG_LOG_DIFF: Important difference between sample\'s calculated UG');
		}
		if (this.analysis_results.ug_under_saturation && this.analysis_results.ug_under_saturation.value === 0) {
			error_list.push('ERR_UG_GREATER_THAN_SAT: UG result is greater than saturation value');
		}
		if (this.analysis_results.ug_greater_vmr && this.analysis_results.ug_greater_vmr.value === 0) {
			error_list.push('WARN_UG_VMR: UG calculated smaller than minimum reporting value');
		}
		if (this.analysis_results.result_error_code.value > 0) {
			const error_list_hmi = [];
			const error_code_value = Array.from(this.analysis_results.result_error_code.value.toString(2)).reverse();
			for (let i = error_code_value.length - 1; i > -1 ; i--) {
				if (i === 0 && error_code_value[i] === '1') { // bit 0
					error_list_hmi.push('Warning (HMI): Result below the minimum reportable value');
				}
				if (i === 2 && error_code_value[i] === '1') { // bit 2
					error_list_hmi.push('Warning (HMI): TT-Measurements well 2 out of bound');
				}
				if (i === 3 && error_code_value[i] === '1') { // bit 3
					error_list_hmi.push('Warning (HMI): TT-Measurements well 3 out of bound');
				}
				if (i === 4 && error_code_value[i] === '1') { // bit 4
					error_list_hmi.push('Warning (HMI): TT-Calibrators well 1 out of bound');
				}
				if (i === 5 && error_code_value[i] === '1') { // bit 5
					error_list_hmi.push('Warning (HMI): TT-Calibrators well 4 out of bound');
				}
				if (i === 6 && error_code_value[i] === '1') { // bit 6
					error_list_hmi.push('Error (HMI): TT-Calibrators Invalid');
				}
				if (i === 7 && error_code_value[i] === '1') { // bit 7
					error_list_hmi.push('Error (HMI): TT-Measurements Invalid');
				}
				if (i === 8 && error_code_value[i] === '1') { // bit 8
					error_list_hmi.push('Error (HMI): S0 Invalid');
				}
				if (i === 9 && error_code_value[i] === '1') { // bit 9
					error_list_hmi.push('Error (HMI): Temperature Invalid');
				}
				if (i === 10 && error_code_value[i] === '1') { // bit 10
					error_list_hmi.push('Warning (HMI): Significant difference between the two TT-Calibrators');
				}
				if (i === 11 && error_code_value[i] === '1') { // bit 11
					error_list_hmi.push('Warning (HMI): Significant difference between the two TT-Measurements');
				}
				if (i === 12 && error_code_value[i] === '1') { // bit 12
					error_list_hmi.push('Error (HMI): Split VMR');
				}
			}
			error_list = error_list.concat(error_list_hmi.reverse());
		}

		if (error_list.length > 0) {
			this.analysis_results.errors = error_list.join('\n');
			this.analysis_results.has_error = true;
		}
	}

	back(): void {
		this.navigation.back();
	}

	public openFullscreenChart(chartName: string) {
		if (chartName === 'Pressure per well S0') {
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'Pressure per well', this.analysis_id, this.machine_id, 1105, 0, this.analysis_results.sampling_time, 0])
			);
			window.open(url, '_blank');
		} else if (chartName === 'Pressure per well S1') {
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'Pressure per well', this.analysis_id, this.machine_id, 1109, 0, this.analysis_results.sampling_time, 0])
			);
			window.open(url, '_blank');
		} else if (chartName === 'Pressure per well Air') {
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'Pressure per well', this.analysis_id, this.machine_id, 1113, 0, this.analysis_results.sampling_time, 0])
			);
			window.open(url, '_blank');
		} else if (chartName === 'Fluo Data Chart') {
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'Fluo Data Chart', this.analysis_id, this.machine_id, null, 0, this.analysis_results.sampling_time, 0])
			);
			window.open(url, '_blank');
		} else if (chartName === 'Pressure Seq 2.4 Chart') {
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'Pressure Seq 2.4 Chart', this.analysis_id, this.machine_id, 1021, 0, this.analysis_results.sampling_time, 0])
			);
			window.open(url, '_blank');
		} else if (chartName === 'TM Calling') {
			const url = this.router.serializeUrl(
				this.router.createUrlTree(['/chart', 'TM Calling', this.analysis_id, this.machine_id, null, 0, this.analysis_results.sampling_time, 0])
			);
			window.open(url, '_blank');
		}
	}

	public calculateAverage(ratio1: number, ratio4: number, puit1A: number, puit1B: number, puit4A: number, puit4B: number): number {
		let avg = 0;
		avg = (ratio1 * puit1A) + ((1 - ratio1) * puit1B) + (ratio4 * puit4A) + ((1 - ratio4) * puit4B);
		return (avg / 2);
	}

	public getTmCallingFlag(fluo_melting_temp: number, avg: number): boolean{
		const result = (fluo_melting_temp - avg);
		if (result > 2 || result < -2 ){
			return this.tm_calling_flag = true;
		}
		return this.tm_calling_flag = false;
	}

	public getMissingDiagnostic(): string {
		let tooltip = '';
		for (let i = 0; i < this.missing_variable.length; i++) {
			tooltip += (this.missing_variable[i].map.var_tag + '\n');

		}
		return tooltip;
	}

	public reprocessCalculations() {
		this.reprocessingCalculations = true;
		this.service.reprocessCalculations(this.analysis_id).subscribe(value => {
			this.reprocessingCalculations = false;
			if (value == 204) {
				this.snackbar.open('Recalculated data!', 'Ok', {
					duration: 3000
				});
			} else {
				this.snackbar.open('An error has occurred. Code : ' + value, 'Ok', {
					duration: 3000
				});
			}
		});
	}
}
